#studioframe {
  width: 100%;
  height: 100%;
  background: #fff;

  #root {
    width: 100%;
    height: 100%;
  }

  &.fancybox-content {
    padding: 0;
  }
}

.fancybox-slide {
  padding: 0!important;
}

.fancybox-active {
  overflow: hidden;
}

.product-more-content {
  .slice-alternative_configurateur_mobile, .slice-prestashop_widget{
    display: none;
  }
}

#configurateur-mobile {
  width: 100%;
  height: 100%;
  background: #fff;
  display:none;

  img {
    display: block;
    margin: 0 auto;
  }

  h1 {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size:20px;
    letter-spacing: 1px;
    line-height: 21px;
  }

  p {
    text-align: center;
    line-height: 21px;
  }

  .is_configurateur {
    height: 170px;

    form > div:first-child {
      padding-bottom: 20px;
      margin-bottom: 30px;
    }
  }
}

