$inputs-height: 45px;
$inputs-padding: 10px;

// Layout
// ---------------------------------------------------------------------------------------------------------------------


form:not(#PM_ASForm_1) {
  .row {
    div[class*=col] + div[class*=col] {
      margin-top: 15px;
    }
  }
}

.gformbuilderpro_form .row {
  div[class*=col] + div[class*=col] {
    margin-top: 0px!important;
  }
}

// Inputs
// ---------------------------------------------------------------------------------------------------------------------

.form-control-label {
  font-size: 1.2rem;
  color: map_get($pt-colors, 'black');
  text-align: left;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(#{$inputs-height} - 2px);
}

.form-control {
  font-size: 1.2rem;
  height: $inputs-height;
  background-color: transparent;
  padding: $inputs-padding;
}

.form-control-select {
  padding: 0 $inputs-padding;
}

.input-group .input-group-btn>.btn {
  padding: 0 $inputs-padding;
  line-height: $inputs-height;
  font-size: 1.2rem;
  color: map_get($pt-colors, 'black');
  border: solid 1px map_get($pt-colors, 'black');
  border-left: 0;
  height: $inputs-height;
}

.group-span-filestyle .btn-default {
  background: transparent;
  color: map_get($pt-colors, 'black');
}

.input-group .input-group-btn>.btn[data-action=show-password] {
  line-height: 1;
  background: #000;
  color: #fff;
  font-weight: 500;
}

// Radios
// ---------------------------------------------------------------------------------------------------------------------

.radio-inline {
  font-size: 1.2rem;
  color: map_get($pt-colors, 'black');
  margin-right: 10px!important;

  .custom-radio {
    border: solid 1px #000;
  }
}

.custom-radio {
  position: relative;
  top: -1px;
  margin-right: 3px!important;
}

.custom-radio input[type=radio]:checked+span {
  left: 3px;
  top: 3px;
}

// Checkbox
// ---------------------------------------------------------------------------------------------------------------------

.custom-checkbox {

  display: flex;

  input[type=checkbox]+span .checkbox-checked {
    margin: 0;
    font-size: 1.8rem;
    color: #f5633e;
  }

  input[type=checkbox]:focus,
  input[type=checkbox] {

    margin-top: 0;

    & +span {
      height: 20px;
      border: solid 1px #000;
    }

    width: 20px;
    height: 20px;
  }

  span {
    flex: 0 0 20px!important;
    width: 20px!important;
  }

  label {
    font-size: 1.2rem;
    margin-bottom: 0;
    line-height: normal;
    align-self: center;
  }
}

