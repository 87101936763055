.richtext-entry {

  & > *:first-child {
    margin-top: 0;
    padding: 0;
  }

  max-width: 770px;
  margin: 0 auto;

}