.filters,
.quickview {
  .modal-dialog {
    max-width: 1040px;
  }

  .modal-content {
    background: transparent;
    border: none;
    border-radius: 0;
  }

  .modal-body {
    background: map_get($pt-colors, 'white');
    border: solid 1px map_get($pt-colors, 'black');
    padding: 0;
  }

  .modal-header {

    padding: 0;
    text-align: right;
    border: 0;

    .qv-btn-see-product,
    button.close {
      background: map_get($pt-colors, 'white');
      opacity: 1;
      display: inline-block;
      height: 85px;
      padding: 0 47px ;
      border: solid 1px map_get($pt-colors, 'black');
      float: none;
      box-shadow: none;
      font-weight: $theme-text-font-weight;
      line-height: 85px;
      font-size: 1.2rem;
      vertical-align: middle;
      margin: 0;

      svg,
      span {
        z-index: 3;
        user-select: none;
        position: relative;
        vertical-align: middle;
      }

      svg {
        margin-left: 20px;
      }

      @include link-colors(#000,#000,#000,#000,#000);
    }

    .qv-btn-see-product {
      border-right: 0!important;
    }

    & > * {
      border-bottom: 0!important;
    }
  }

  .col-border-right {
    border-right: solid 1px map_get($pt-colors, 'black');
  }

  .quickview-col {
    @include media-breakpoint-up(md) {
      //height: 518px;
    }
  }

  .quickview-col-image {
    background: #fff;
    position: relative;
  }

  .qv-product-image-slider {
    display: none;

    &.flickity-enabled {
      display: block;
    }
  }

  .qv-product-image-cell {
    width: 100%;
  }

  .qv-product-image-slider-arrows {
    position: absolute;
    width: 100%;
    z-index: 1;

    .custom-arrow {
      width: 60px;
      height: 60px;
      top: calc(50% - 30px);
      @include transform2d(translateX(0px));
      cursor: pointer;


      &.previous {
        left: -30px;
        &:hover {
          @include transform2d(translateX(-5px));
        }
      }

      &.next {
        right: -30px;

        &:hover {
          @include transform2d(translateX(5px));
        }
      }
    }
  }
}

