.sitemap-title {
  text-transform: uppercase;
}

.sitemap {

  margin-top: 0;

  h2 {
    font-family: $theme-title-font;
    color: map_get($pt-colors,'black');
    border: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0;
    width: 100%;
    height: auto;
  }

  ul {

    margin: 0;

    li {
      font-size: 1.2rem;
      margin-bottom: 8px;
    }

    &.nested {
        margin-top: 8px;
    }
  }

  a {
    @include link-colors(
                    map_get($pt-colors,'black'),
                    map_get($pt-colors,'black'),
                    map_get($pt-colors,'black'),
                    #999
    );

    &:hover {
      border-bottom: solid 1px;
    }
  }
}