// Addresses
// ---------------------------------------------------------------------------------------------------------------------

.page-addresses {

  .address {
    color: map_get($pt-colors, 'black');
    padding: 0px;
    margin-bottom: 15px;
    box-shadow: none;
    border: solid 1px #000;

    .address-body {

      padding: 15px;

      h4 {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 10px;
      }

      address {
        font-size: 1.3rem;
        margin-bottom: 0;
      }
    }

    .address-footer {
      border-top: solid 1px #000;
      padding: 10px 15px;

      a {
        @include link-colors(map_get($pt-colors, 'black'),map_get($pt-colors, 'black'),map_get($pt-colors, 'black'));
        margin-right: 8px;

        &:hover {
          text-decoration: none;

          span {
            border-bottom-color: #000;
          }
        }

        i {
          font-size: 1.4rem;
        }

        span {
          font-size: 1.2rem;
          display: inline-block;
          @extend .text-border-bottom;
          border-bottom-color: #fff;
        }
      }
    }
  }

  .addresses-footer {
    margin: 0;

    a {
      border: solid 1px #000;
      padding: 5px 10px;
      display: inline-block;
      @include link-colors(map_get($pt-colors, 'black'),map_get($pt-colors, 'black'),map_get($pt-colors, 'black'));

      i {
        font-size: 1.4rem;
        position: relative;
        top:-1px;
      }

      span {
        font-size: 1.2rem;
        @extend .text-border-bottom;
        border-bottom-color: #fff;
      }

      &:hover {
        text-decoration: none;

        span {
          border-bottom-color: #000;
        }
      }
    }
  }

}

// Customer layout
// ---------------------------------------------------------------------------------------------------------------------

#main .page-footer {

  margin-top: 30px;

  a {
    display: inline-block;
  }
}

.form-footer {
  .btn-primary {
    @extend .btn-primary-black;

    span {
      border-bottom-color: transparent;
    }

    &:hover {
      background: map_get($pt-colors, 'black');

      span {
        border-bottom: solid 1px map_get($pt-colors, 'white');
      }
    }

  }
}

.page-customer-account #content {
  color: map_get($pt-colors, 'black');
  font-size: 1.2rem;
}

// Order detail
// ---------------------------------------------------------------------------------------------------------------------

.page-order-detail {

  font-size: 1.2rem;
  color: map_get($pt-colors, 'black');

  strong {
    font-weight: 600;
  }

  .box {
    box-shadow: none;
    border: solid 1px map_get($pt-colors, 'black');
  }

  .addresses {
    margin: 0;
  }
}

// Table layout
// ---------------------------------------------------------------------------------------------------------------------

.table-labeled .label {
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.5rem 0.8rem;
}

.page-customer-account {
  .table.table-bordered {
    border: solid 1px map_get($pt-colors, 'black');
  }
}

.table {
  margin-bottom: 0;
}

* + .table {
  margin-top: 1rem;
  margin-bottom: 0;
}

// Authentification
// ---------------------------------------------------------------------------------------------------------------------

.login-form {
  margin-top: 0;
}

.page-customer-account #content .forgot-password {
  text-align: right;
  margin-top: 0;

  a {
    font-size: 1.1rem;
    @include link-colors(map_get($pt-colors, 'black'),map_get($pt-colors, 'black'),map_get($pt-colors, 'black'));

    &:hover {
      text-decoration: none;
      @extend .text-border-bottom;
    }
  }
}

// Password reset
// ---------------------------------------------------------------------------------------------------------------------

.forgotten-password {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  .send-renew-password-link {
    text-align: justify;
    text-align-last: center;
    padding: 0!important;
  }
}