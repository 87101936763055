.cta {
  &.img-background {
    padding: 210px 0px;
  }
  padding: 130px 0px;
  text-align: center;
  position: relative;

  @include media-breakpoint-down(sm){
    padding: 60px 10px !important;
  }

  img {
    width: 100%;
  }

  .cta-background-img {
    position: absolute;
    z-index: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &-entry {
    width: 100%;
    max-width: 770px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    h2,
    h1,
    h3{
      font-weight: 600;
      font-size: 2.21rem;
      letter-spacing: 1.1px;
      margin-top: 0;
      font-size: 3rem;

      @include media-breakpoint-down(sm){
        margin-bottom: 0;
      }
    }
  }

  .entry p {
    color: inherit;
    font-size: 1.4rem !important;
    margin-bottom: 50px;
    font-family: graphik,sans-serif;

    @include media-breakpoint-down(xl){
      margin-bottom: 25px;
    }

    @include media-breakpoint-down(sm){
      margin-top: 20px;
    }
  }
  
  .cta-footer {
    margin-top: 20px;
    position: relative;
  
    @include media-breakpoint-down(sm){
      margin-top: 10px;
    }
  
    a {
      font-size: 1.2rem;
  
      span {
        text-transform: uppercase;
        border-bottom: none;
  
        @include media-breakpoint-down(sm){
          font-size: 10px;
        }
      }
  
      &:before {
        background: #ecb7b7;
      }
    }
  }
  .container-cta {
    position: relative;
    margin: 0 0 20px;
    display: inline-flex;
  
    @include media-breakpoint-down(sm) {
      margin: 0 0 50px;
    }
  
    a {
      position: relative;
      z-index: 1;
      background: #fff;
      text-transform: uppercase;
  
      &:hover {
        transform: translate(10px, 10px);
      }
    }
  
    span {
      background-color: #ecb7b7;
      position: absolute;
      z-index: 0;
      right: -10px;
      bottom: -10px;
      border: 1px solid #000;
      width: 100%;
      height: 100%;
    }
  }
}

