// Variables
// ---------------------------------------------------------------------------------------------------------------------

$header-pt-height: 70px;

// Components
// ---------------------------------------------------------------------------------------------------------------------

.menu-item-underlined {
  border-bottom: solid 1px;
  color: #000;
  padding-bottom: 2px;
}

.menu-item-tab {
  display: flex;
  border-right: solid 1px;
  height: 52px;
  min-width: 52px;

  @media (min-width: 1081px) {
    height: $header-pt-height;
    min-width: $header-pt-height;
  }
}

.header-pt-logo-wrapper + .menu-item-tab {
  border-right: solid 0px!important;
}

.menu-item-tab:last-child {
  border: 0;
}

.menu-item-tab.menu-item-tab--large {
  min-width: 90px;
}

.menu-item-tab > a {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  &:hover {
    background: #000;

    & > span {
      color: #fff
    }
  }
}

.menu-item-tab > a > span {
  @extend .menu-item-underlined;
  border-color: #fff;
  color: #000;

  &:hover {
    border-color: #000;
  }
}

// Global Layout Header
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 80;
}

.header-pt-wrapper-menu {
  & > .topheader,
  & > .container {
    position: relative;
    z-index: 2;
  }
}

.header-pt-wrapper-submenu {
  position: absolute;
  top: 0;
  height: 570px;
  width: 100%;
  /*max-width: 1440px;*/
  margin-left: 0 !important;
  left: 0 !important;
  background: #fff;
  z-index: 1;
  padding-top: 150px;

  // @todo Make an animation for the wrapper submenu
  display: none;

  &.header-pt-wrapper-submenu--visible {
    display: block;
    opacity: 0;
    animation-name: submenuWrapperAnimationAppear;
    animation-duration: 250ms;
    animation-fill-mode: forwards;
  }
}

.header-pt-wrapper-menu--topheader-hidden + .header-pt-wrapper-submenu {
  height: 539px;
  padding-top: 119px;
}

.header-pt {
  height: 52px;
  border-bottom: solid 1px #000;
  border-top: 0;
  display: flex;
  background: #fff;
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 1px;
    background: #000;
    width: 100%;
    z-index: 100;
    position: absolute;
    left: 0;
    bottom: -1px;
    pointer-events: none;
  }

  @media (min-width: 1081px) {
    height: $header-pt-height;
  }
}

@keyframes submenuWrapperAnimationAppear {
  0%   {display: none;}
  1%   {
    opacity: 0;
    transform: translateY(-520px);
    display: block; }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// pm_advancedtopmenu
// ---------------------------------------------------------------------------------------------------------------------

#adtm_menu ul#menu .li-niveau1:hover div.adtm_sub {
  transition: visibility 0s linear 100ms, opacity 100ms linear 100ms;
}

#adtm_menu ul#menu .li-niveau1 div.adtm_sub {
  padding: 10px 0 0 0;


  @media (min-width: 835px) {
    min-height: 460px;
  }

  @media (max-width: 1080px) {
    padding: 0;
  }
}

.column_wrap_title {
  text-transform: uppercase!important;
}

body #adtm_menu ul#menu li.li-niveau1.advtm_menu_toggle,
body #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.advtm_menu_toggle {
  display: none!important;
}

.adtm_menu_mobile_mode .adtm_toggle_menu_button {
  border: none!important;
}

#adtm_menu ul li.advtm_menu_toggle a.adtm_toggle_menu_button span.adtm_toggle_menu_button_text {
  font-size: 16px;
  min-height: 51px;
  line-height: 51px;
}

.menu-item-nav {
  order: 99;
  @include media-breakpoint-up(md) {
    order: unset;
  }
}

#adtm_menu .li-niveau1 > a {


  span {
    @media (max-width: 1080px) {
      font-weight: normal;
      text-transform: none;
    }
  }

  border-bottom: solid 1px #000!important;

  @media (min-width: 1081px) {
    border-left: solid 1px #000!important;
    border-left-width: 1px!important;
    border-bottom: none!important;
  }
  

  @media (max-width: 1080px) {
    display: block;
  }

  &:hover {
    background: #000!important;
    color: #fff!important;
    text-decoration: none!important;
    
    & > span {
      background: #000!important;
      color: #fff!important;
    }

  }
}

#adtm_menu {
  .product-miniature {
    margin-bottom: 0!important;
  }

  .featured-products {
    margin: 0!important;
    padding-bottom: 0!important;
  }
}

.adtm_sub .columnWrapTable {
  max-width: 1270px!important;
  margin: 0 auto!important;
}

.adtm_column .adtm_elements {
  @media (min-width: 1081px) {
  margin-bottom: 25px!important;
  }
}

@media (min-width: 1081px) {
  #adtm_menu_inner {
    border: none!important;
    border-right: solid 1px #000!important;
  }
}


@media (max-width: 1080px) {
  .menu-item-nav {
    position: absolute;
    left: 0;
    top: 51px;;
  }

  #adtm_menu_inner {
    border: none;
  }

  :root {
    --adtm-menu-item-height: 50px;

    @media (max-width: 1080px) {
      --adtm-menu-item-height: 40px;
    }
  }

  #adtm_menu .li-niveau1 a.a-niveau1 .advtm_menu_span,
  #adtm_menu .li-niveau1,
  #adtm_menu .li-niveau1 a.a-niveau1 {
    min-height: var(--adtm-menu-item-height);
    line-height: var(--adtm-menu-item-height);
  }
}

.sub.adtm_sub_open .advtm_menu_span {
  background-color: #000!important;
  color: #fff!important;
}


#adtm_menu {
  z-index: 40;

  @media (max-width: 1080px) {
    height: calc(100vh - 51px);
    overflow: scroll;
  }
}

.menu-item-tab-mobile {
  display: none;
  @media (max-width: 1080px) {
    display: block;
  }
}

#adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub a.a-niveau1 span {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPGcgZmlsbD0iIzAwMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxyZWN0IGNsYXNzPSJiYXItaCIgeD0iMCIgeT0iMTAiIHdpZHRoPSIyMCIgaGVpZ2h0PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxyZWN0IGNsYXNzPSJiYXItdiIgeD0iMTAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjIwIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9zdmc+")
}


#adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub.adtm_sub_open a.a-niveau1 span {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPGcgZmlsbD0iI2ZmZiI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxyZWN0IGNsYXNzPSJiYXItaCIgeD0iMCIgeT0iMTAiIHdpZHRoPSIyMCIgaGVpZ2h0PSIxIj48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPC9zdmc+")
}






// Z-Index management
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-wrapper-menu {
  position: relative;
  z-index: 21;
}

.header-pt-desktop {
  z-index: 20;
}

.header-pt-overlay {
  z-index: 19;
}

// Logo
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-logo-wrapper {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  margin-right: auto;

  @media (min-width: 1081px) {
    padding: 0 0 0 17px;
    margin: 0;
    flex: 1;
  }
}

.header-pt-logo {
  position: relative;
  top: 3px;

  @media (min-width: 1081px) {
    position: static
  }
}

.header-pt-logo-wrapper-mobile {
  @extend .header-pt-logo-wrapper;
  padding: 0 0 0 15px;

  width: 50px;

  svg {
    width: 170px;
  }
}

.header-pt-logo {
  width: 160px;
  @extend .align-self-center;
  @include transform2d(translateX(-46px));
  @include transition(all 450ms 200ms $easeInOutExpo);

  -webkit-clip-path: polygon(22% 0, 100% 0, 100% 100%, 22% 100%);
  clip-path: polygon(22% 0, 100% 0, 100% 100%, 22% 100%);

  /* Logo diplayed with tiger monogram only */
  &.header-pt-logo--monogram {
    @include transform2d(translateX(0px));
    -webkit-clip-path: polygon(0% 0, 26% 0, 22% 100%, 0% 100%);
    clip-path: polygon(0% 0, 22% 0, 26% 100%, 0% 100%);
  }
}

// Header Navbar
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-navbar {
  display: flex;
  @include transform2d(translateX(0px));
  @include transition(all 300ms $easeInOutExpo);


  & > div:first-child {
    border-left: solid 1px #000;
  }

  &.header-pt-navbar--opened {
    /*@include transform2d(translateX(75px));*/
  }
}

@include media-breakpoint-up(lg) {
  .header-pt-navbar {
    &.header-pt-navbar--opened {
      @include transform2d(translateX(0px));
    }
  }
}

// Header Menu
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-menu {
  display: flex;
}

// Icons items
// ---------------------------------------------------------------------------------------------------------------------

.header-item-icon {
  width: 100%;

  & > a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: inherit;
      fill: currentColor;
    }

    &:hover {
      color: #fff;
      background: #000;

      g {
        stroke: #fff;
      }
    }
  }
}

// Module in header
// We know we talk modules and styles should be put in a dedicated file in modules/ folder but it's more convenient
// for the header rebranding... Maybe later.
// ---------------------------------------------------------------------------------------------------------------------

// Language Selector
.header-pt-languages {
  text-transform: uppercase;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  a {
    display: block;
    padding: 8px 2px;
    margin: 0 2px;
  }

  li {
    display: inline-block;

    a:hover span,
    &.current a span {
      @extend .menu-item-underlined;
    }
  }

  @media (min-width: 1081px) {
    display: flex;
  }
}

// Cart
.header-pt-cart {
  .blockcart-wrapper,
  .blockcart-link,
  .blockcart {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.header-pt-cart,
.blockcart-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

// Search
.menu-item-search {
  overflow: hidden;
  width: 52px;

  &.menu-item-search--opened {
    width: 240px;
  }

  input[type=text] {
    outline: 0;
  }
}

.menu-item-search--opened + div,
.menu-item-search--opened + div + div {
  display: none!important;
}

.header-pt-navbar--opened + .header-pt-navbar {
  display: none !important;
}

@media (min-width: 1081px) {
  .menu-item-search {
    width: 70px;

    .menu-item-search.menu-item-search--opened {
      width: 240px;
    }
  }
}

.header-pt-searchbar {
  display: flex;
  flex: 1 0 240px;
  position: relative;
  left: 0;

  &__search-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  input[name=s] {
    appearance: none;
    border: 0;
    border-bottom: solid 1px #000;
    border-radius: 0;
    box-shadow: none;
  }
}

.btn-open-menu,
.btn-open-search  {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1081px) {
    height: $header-pt-height;
    width: $header-pt-height;
  }
}

.btn-open-menu {

  #cross {
    display: none;
  }

  &.btn-open-menu--active {
    #cross {
      display: block;
    }
    #menu {
      display: none;
    }
  }
}

// Blockcart count
.blockcart-link {
  position: relative;
}

.header-pt .blockcart__count {
  width: 17px;
  height: 17px;
  background: #000;
  box-shadow: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff!important;
  font-weight: bold;
  font-size: 9px;
  position: absolute;
  right: -12px;
  top: -10px;

  span {
    position: relative;
    top: 1px;
  }
}

a:hover .blockcart__count {
  background: #fff;
  color: #000!important;
}

// Submenu layout
// ---------------------------------------------------------------------------------------------------------------------

.submenu {
  display: none;
  height: 379px;
  margin: 0 -10px;

  & > div {
    margin: 0 10px;
  }

  & > .submenu-1-5 {width: 20%;}
  & > .submenu-2-5 {width: 40%;}
  & > .submenu-3-5 {width: 60%;}
  & > .submenu-4-5 {width: 80%;}
  & > .submenu-5-5 {width: 100%;}

  & > .submenu-offset-1-5 {margin-left: 20%}
  & > .submenu-offset-2-5 {margin-left: 40%}
  & > .submenu-offset-3-5 {margin-left: 60%}
  & > .submenu-offset-4-5 {margin-left: 80%}
  & > .submenu-offset-5-5 {margin-left: 100%}

  // @todo Make an animation for the submenu

  &.submenu--visible {
    display: flex;
    opacity: 0;
    animation-delay: 250ms;
    animation-duration: 100ms;
    animation-name: submenuAnimationAppear;
    animation-fill-mode: forwards;
  }
}

@keyframes submenuAnimationAppear {
  0%   {display: none;}
  1%   {
    opacity: 0;
    transform: translateY(-20px);
    display: flex; }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

// Submenu Title
// ---------------------------------------------------------------------------------------------------------------------

.submenu-title {
  font-size:16px;
  font-weight: 300;
  margin-bottom: 20px;
  line-height: 20px;

  div {
    border-bottom: solid 1px;
    padding-bottom: 3px;
    display: inline-block;
  }

}

.submenu-list-items {
  font-size: 13px;
  margin-bottom: 20px;
  line-height: 16px;

  ul {
    margin: 0;
    padding: 0;

    li + li {
      margin-top: 6px;
    }
  }

  a:hover {
    text-decoration: underline !important;
  }
}

.submenu-image-item {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.submenu-list-header {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 16px;
}

.submenu-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.submenu-item + .submenu-item {
  margin-top: 40px;
}

.submenu-item {
  display: flex;
  flex-direction: column;
}

.submenu-blocks {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & > div {
    max-height: 328px;
  }
}

/* Height modifier for desktop menu */

// Header overlay
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-overlay {
  position: fixed;
  width: 0;
  height: 0;
  background: rgba(0,0,0,.15);
  z-index: 19;
  opacity: 0;
  pointer-events: none;
}

.header-pt-wrapper-submenu--visible + .header-pt-overlay {
  transition: opacity 250ms $easeInCubic;
  opacity: 1;
  width: 100%;
  height: 100%;
}

// Mobile menu
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-mobile {
  display: none ;
  &.header-pt-mobile--show {
    display: block;
  }
}

.mobile-menu {
  max-height: calc(100vh - 210px);
  overflow: scroll;
  background: #ffffff;
  border-left: solid 1px #000;
  border-right: solid 1px #000;
  border-bottom: solid 1px #000;
}

.mobile-menu-item-tab {
  background: #fff;
}

.mobile-menu-item-tab + .mobile-menu-item-tab {
  border-top: solid 1px #000;
}

a.mobile-menu-item-tab-link {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 43px;
  align-items: center;
  padding: 0 34px;
  position: relative;

  svg {
    position: absolute;
    right: 14px;
    display: none;
  }

  &.js-toggle-submenu--active {
    svg {
      g {
        fill: #fff;
      }
      .bar-v {
        display: none;
      }
    }
  }

  &.has-submenu svg {
    display: block;
  }
}

.js-toggle-submenu.mobile-menu-item-tab-link.js-toggle-submenu--active {
  background: #000;
  color: #fff!important;
}

.mobile-menu-submenu-wrapper {
  display: none;
  /*max-height: calc(100vh - 410px);
  overflow: scroll;*/

  &.mobile-menu-submenu-wrapper--open {
    display: block;
  }
}

.mobile-menu-submenu {
  padding: 0 34px;

  .submenu-list-items {
    margin-bottom: 0;
  }
}

.submenu-see-all {
  font-style: italic;
}

.js-toggle-submenu + * {
  display: none;
}

.js-toggle-submenu.js-toggle-submenu--active + * {
  display: block;
}

.submenu-list-header.js-toggle-submenu.js-toggle-submenu--active + * {
  margin-bottom: 15px;
}

.mobile-menu-item-tab-link.js-toggle-submenu--active + * {
  padding-top: 34px;
  padding-bottom: 10px;
}

.submenu-title.js-toggle-submenu.js-toggle-submenu--active + * {
  margin-bottom: 20px;
}

.mobile-language-selector{
  display: none;
}

.adtm_menu_toggle_open {
  .mobile-language-selector{
    display: none;
  
    @media (max-width: 1080px) {
      display: block;
    }
  }
}



.mobile-language-selector ul {
  border-bottom: solid 1px #000;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    height: var(--adtm-menu-item-height);
    padding: 0 10px;
    text-align: left;
    flex: 1;
  }

  li + li {
    border-left: solid 1px #000;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
}

// Responsive layout modifier
// ---------------------------------------------------------------------------------------------------------------------

.header-pt-desktop {
  .submenu-item {
    max-height: 392px;
  }

  .submenu > div {
    height: 392px;
  }
}

.header-pt-mobile {
  .submenu-item + .submenu-item {
    margin-top: 0px;
  }

  .submenu-list-items + .submenu-list-items {
    margin-top: 8px;
  }

  .submenu-list-items:first-child {
    margin-top: 0;
  }
}

.mobile-menu-item-tab a[data-uid="produits"].js-toggle-submenu--active + .mobile-menu-submenu-wrapper {
  display: flex;
  flex-direction: column;

  & > div:first-child {
    order: 2;
  }

  & > div:last-child {
    order: 1;
  }
}

// Responsive utilities
// ---------------------------------------------------------------------------------------------------------------------

.hide-mobile-block {
  @extend .d-none;
  @extend .d-lg-block;
}

.show-mobile-block {
  @extend .d-block;
  @extend .d-lg-none;
}

.hide-mobile-flex {
  @extend .d-none;
  @extend .d-lg-flex;
}

.show-mobile-flex {
  @extend .d-flex;
  @extend .d-lg-none;
}

