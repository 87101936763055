.three-columns {

  .column {
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    > div {

      @include media-breakpoint-up(lg) {
        height: calc((100% - 60px) / 3);
      }

      &.cont-3{
        @include media-breakpoint-between(sm,md) {

          height: calc(100% / 3);

          &:first-child{
            height: calc((100% / 3) - 20px);
          }
        }
      }

      &.container-text {
        border: 1px solid;

        @include media-breakpoint-down(md) {
          height: calc(100% / 3);
        }

        @include media-breakpoint-down(sm) {
          margin: 10px 0;
          min-height: initial;
        }
      }
    }

    .video {

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .container-img {

      @include media-breakpoint-up(lg) {
        height: calc(((100% - 15px) / 3) * 2);
      }
    }

    .entry-text {
      line-height: 18px;
      letter-spacing: 1.5px;
      height: 100%;
      position: relative;

      .cont {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 80%;
        text-align: center;

        @include media-breakpoint-down(md) {
          width: 95%;
        }

        @include media-breakpoint-down(lg) {
          width: 90%;
        }

        @include media-breakpoint-down(sm) {
          position: inherit;
          transform: inherit;
          width:100%;
          padding: 20px 0;
          left: inherit;
          top: inherit;
        }

        p {
          font-weight: 600;
          font-size: 1.3rem !important;

          @include media-breakpoint-down(md) {
            font-size: 1.1rem !important;
            line-height: 12px;
          }
        }

        h2 {
          font-weight: 600;
          font-size: 2.21rem;
          letter-spacing: 2.5px;
          margin-top: 0;
          text-transform: uppercase;

          @include media-breakpoint-down(md) {
            margin-bottom: 2px;
          }
          @include media-breakpoint-down(lg) {
            margin-bottom: 5px;
          }
        }

        h3 {
          @include media-breakpoint-down(md) {
            margin-block-end: 0;
          }
        }
      }
    }
  }
}

.plyr__control--overlaid {
  background: #fff;
  color:#000;
}