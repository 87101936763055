.slice-mise_en_avant_d_une_categorie .slice-header {
  position: relative;
  top: -55px;
  z-index: 2;
  left: 30px;
  text-align: left;
  font-size: 6.5rem;
  line-height: 1;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 3.5rem;
    line-height: 1.1;
    position: static;
  }

}

.category-featured-products {
  display: grid;
  height: 80vh;
  z-index: 1;

  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;

  @include media-breakpoint-up(md) {
    height: 60vh;
    grid-gap: 30px;
  }

  @include media-breakpoint-up(lg) {
    height: 80vh;
    grid-gap: 50px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 700px;
    height: 70vh;
    grid-gap: 70px;
  }

  & > div {

    overflow: hidden;
    
    &:nth-child(1) {
      grid-column: 1 / span 16;
      grid-row: 1 / span 1;

      @include media-breakpoint-up(md) {
        grid-column: 1 / span 9;
        grid-row: 1 / span 2;
      }

      .product-thumbnail {
        img {
          padding: 10px 0;

          @include media-breakpoint-up(md) {
            padding: 70px 0;
          }

        }
      }
    }

    &:nth-child(2) {
      grid-column: 1 / span 8;
      grid-row: 2;

      @include media-breakpoint-up(md) {
        grid-column: 10 / span 7;
        grid-row: 1;
      }

    }

    &:nth-child(3) {
      grid-column: 9 / span 8;
      grid-row: 2;

      @include media-breakpoint-up(md) {
        grid-column: 10 / span 7;
        grid-row: 2 / span 1;
      }
    }
  }

  .product-miniature {
    height: 100%;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .product-thumbnail {
      height: auto;
      flex: 1;
    }
  }

}

