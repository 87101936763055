#index, #module-w3prismic-single {
    .trustpilot-widget {
        margin: 40px 0px;
        padding: 0 15px;
    }
}

#product {
    .trustbox-review-title {
        text-transform: uppercase;
        font-size: 5.4rem;
        font-family: "Futura LT",Futura,sans-serif;
        font-weight: bold;
        line-height: 1.1;
        text-align: center;
        position: relative;
        -webkit-text-stroke: 1px black;
        color: #fff;
        z-index: 2;
        letter-spacing: 4px;
        margin-top: 30px;
        @media screen and (max-width: 1200px) {
            font-size: 4rem;
        }
        @media screen and (max-width: 991.98px) {
            font-size: 3rem;
        }
    }
    .trustpilot-widget {
        &.mini-product {
            margin-top: 12px;
            left: -52px;
        }
        &.reviews {
            margin: 40px 0px 80px;
            padding: 0 15px;
        }
    }
}

// #checkout {
//     .checkout-cart-summary {
//         .checkout-cart-summary-wrapper {
//             .trustpilot-widget {
//                 &.mini {
//                     margin-top: 30px;
//                 }
//             }
//         }
//     }
// }
