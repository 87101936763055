// Advanced Search styles
// ---------------------------------------------------------------------------------------------------------------------

.products-filters {
  background: map_get($pt-colors,'white');
  padding:30px;

  @include media-breakpoint-up(sm) {
    padding: 50px;
  }


  .PM_toggleCheckbox {
    display: none;
  }

  .PM_ASCriterionsGroupTitle {
    border-top: solid 1px map_get($pt-colors,'black');
    color: solid 1px map_get($pt-colors,'black');
    padding: 17px 0;
    font-size: 1.2rem;
    text-align: left;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    @include user-select(none);

    @include media-breakpoint-up(sm) {
      padding: 25px 0;
    }

  }

  .PM_ASCriterionsGroupName {
    flex: 1;

    &__badge {
      font-weight: 600;
      background: #000;
      width: 16px;
      height: 16px;
      color: #fff;
      text-align: center;
      border-radius: 50%;
      font-size: 11px;
      display: none;

      &.-visible {
        display: inline-block;
      }
    }
  }

  .PM_ASCriterionsGroupOuter {
    display: none;
  }

  .PM_ASArrow svg {
    transform-origin: 50% 50%;
    @include transition(all 250ms $easeOutQuart);
  }

  .PM_toggleCheckbox:checked {
    & + .PM_ASCriterionsGroupTitle + .PM_ASCriterionsGroupOuter {
      display: block;
    }

    & + .PM_ASCriterionsGroupTitle .PM_ASArrow svg {
      transform: rotate(-90deg);
    }

    & + .PM_ASCriterionsGroupTitle .PM_ASCriterionsGroupName {
      font-weight: 600;
    }
  }

  .PM_ASCriterionsGroupOuter ul {
    @include list-unstyled();
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .PM_ASArrow {
    position: relative;
    top: 3px;
  }

  .PM_ASBlockOutputHorizontal .PM_ASCriterionsGroup {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;

    &:last-child .PM_ASCriterionsOutput {
      //border-bottom: solid 1px map_get($pt-colors,'black');
    }
  }

  .PM_ASCriterionCheckbox {
    display: none;
  }

  label.PM_ASLabelCheckbox {
    font-size: 1.2rem;
    text-align: left;
    max-width: 100%;
    padding: 5px 5px 5px 0;
    display: inline-block;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    border-bottom: solid 1px transparent;
    font-weight: $theme-text-font-weight;
    display: inline-block;

    &:hover {
      font-weight: $theme-text-font-weight;
      text-decoration: none;
      border-bottom: solid 1px $theme-text-color;
    }

    a,
    a.PM_ASLabelLink {
      display: inline;
      font-weight: $theme-text-font-weight;

      &:hover {
        font-weight: $theme-text-font-weight;
      }
    }

    &.PM_ASLabelCheckboxSelected {
      a,
      a.PM_ASLabelLink {
        padding-bottom: 2px;
        display: inline-block;
        border-bottom: solid 1px $theme-text-color;
        font-weight: $theme-text-font-weight;

        &:hover {
          font-weight: $theme-text-font-weight;
        }
      }
    }
  }

  .PM_ASResetGroup {
    display: none;
  }
}

// Modal
// ---------------------------------------------------------------------------------------------------------------------

.filters {
  .modal-dialog {

    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      width: 600px;
    }

  }
}

// Active Filters
// ---------------------------------------------------------------------------------------------------------------------

.products-filters .active_filters,
.products-filters .active_filters + .PM_ASResetSearch,
.modal-body .PM_ASResetSearch {
  display: none;
}

#js-active-search-filters {

  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: 15px;

  .PM_ASSelections {
    background: #fff;
    border: 0;
  }

  .PM_ASSelectionsRemoveLink {
    &::before {
      display: none;
    }
  }

  .active_filters {
    background: #fff;
    padding: 0;
    margin-bottom: 0;

    .filter-block {
      box-shadow: none;
      padding: 0;
      font-size: 1.2rem;
      margin: 0 15px;

      svg {
        position: relative;
        top: -1px;
      }

      & > * {
        vertical-align: middle;
      }
    }
  }

}


// Filters button
// ---------------------------------------------------------------------------------------------------------------------

.PM_ASSelections + .PM_ASResetSearch {
  display: none;
}


.active_filters {
  a.filter-block,
  a {
    @include link-colors(map_get($pt-colors, 'black'), map_get($pt-colors, 'black'), map_get($pt-colors, 'black'));
  }
}

.PM_ASSelections {
  .PM_ASResetSearch {
    display: block;
    font-size: 1.2rem;
    margin: 0 15px;
    padding: 0;

    &::before {
      content: '';
      background: url('../img/erase-filters.png');
      background-size: cover;
      width: 15px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin-right: 2px;
      top: -2px;
      position: relative;
    }
  }
}

.filters-buttons {
  display: flex;
  flex-wrap: wrap;

  .PM_ASResetSearch:before {
    display: none;
  }

  & > * {
    margin: 0 0 10px;
    display: block;
    flex: 0 0 100%;

    @include media-breakpoint-up(sm) {
      flex: 0 0 50%;
      margin: 0 20px;
    }

    text-align: center;
  }
}

.advancedsearch-seo .active_filters {
  display: none;
}