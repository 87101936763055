.list_categories {
  //max-width: 1238px;
  // max-width: 1650px;
  // width: 86vw;
  // padding: 0px 100px;
  margin: 0 auto;
  @include media-breakpoint-down(lg) {
    min-width: 100%;
  }
  table {
    width: 100%;
    border: 1px solid;

    td{
      text-align: center;
      padding: 30px 0;
      width: 33.3333333%;
      text-transform: uppercase;
      font-family: "Futura LT", Futura, sans-serif;
      letter-spacing: 1.1px;
      font-size: 2.5rem;
      line-height: initial;
      border: 1px solid;

      @include media-breakpoint-down(xl){
        font-size: 2.2rem;
      }

      @include media-breakpoint-down(sm){
        font-size: 11px;
        padding: 20px 10px;
      }

      a{
        text-decoration: initial;
      }
    }
  }
}

body#index .slice-liste_categories {
  padding: 30px 0;
}