.btn-toggle-filter {
    padding: 0;
    width: 100%;
    position: relative;
    border: none;
    left: 10px;

    @include media-breakpoint-up(sm) {
        width: auto;
    }

    @include media-breakpoint-down(sm) {
        // margin: 10px 0 0 10px;
    }

    .container-label {
        background-color: #fff;
        position: absolute;
        z-index: 1;
        right: 10px;
        bottom: 10px;
        width: 100%;
        height: 100%;
        padding: 20px 40px;
        border: 1px solid;
        position: relative;
        transition: 0.3s ease;

        &:hover {
            transform: translate(10px, 10px);
        }

        span {
            font-size: 1.2rem;
        }
    }

    span.shadow {
        background-color: #ecb7b7;
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #000;
        width: 100%;
        height: 100%;
    }
}

.products-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    #js-active-search-filters {
        margin-left: auto;

        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
    }
}

#products {
    @include media-breakpoint-down(sm) {
        // margin-top: 70px;
    }
}

section#products {
    @include media-breakpoint-up(md) {
        padding: 0 15px;
    }
}

.page-module-pm-advancedsearch4-seo {
    .product-list-header {
        margin: 30px 0 20px !important;
    }
}

.-container-products {
    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }
}

.product-list-header {
    text-align: left;
    margin: 30px 0 20px;
    width: 100%;

    @include media-breakpoint-down(sm) {
        margin: 0 0 20px;
        height: initial;
    }

    > div {
        position: relative;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .container-title {
        z-index: 2;
        text-align: center;

        // @include media-breakpoint-up(md) {
        //     margin-top: 20px;
        // }

        @include media-breakpoint-up(md) {
            margin-top: 0px;
            margin-bottom: 20px;
        }
    }

    h1 {
        @extend .text-outline;
        font-size: 3rem;
        z-index: 2;
        margin: 30px 0;
        text-align: center;

        @include media-breakpoint-up(sm) {
            font-size: 5.8rem;
            margin: 0;
        }

        @include media-breakpoint-up(md) {
            // transform: skew(0deg, -11deg);
            text-align: left;
            // width: 490px;
            // position: absolute;
            // top: -152px;
        }
    }
}

.products-selection {
    margin: 0;
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(lg) {
        width: 100%;
        margin-top: 35px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0px;
    }
    @include media-breakpoint-up(sm) {
        display: block;
        // margin: 0 0 30px;
        padding-right: 11px;
    }
}

.product-list-description {
    border: solid 1px map_get($pt-colors, "black");
    padding: 15px;
    text-align: center;
    line-height: 2.4rem;
    font-size: 1.2rem !important;
    font-family: $theme-text-font !important;

    @include media-breakpoint-up(sm) {
        padding: 42px 50px;
    }

    p {
        margin-bottom: 0;
    }

    p + p {
        margin-top: 10px;
    }
}

.products {
    // margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
        // margin-bottom: 70px;
    }
}

.active-search-filters {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

#products {
    .products {
        display: grid;
        grid-template-columns: repeat(4, minmax(200px, 1fr));
        grid-gap: 30px;
        grid-auto-flow: dense;
        padding: 0 15px;

        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(5, minmax(200px, 1fr));
        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(3, minmax(200px, 1fr));
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(2, minmax(40%, 1fr));
            // grid-template-rows: 1;
        }

        .push-edito-container {
            grid-column: span 2;
            // grid-row: 1;

            @include media-breakpoint-down(sm) {
                // grid-column: initial;
            }

            .push-edito {
                background-size: cover;
                background-repeat: no-repeat;

                @include media-breakpoint-up(sm) {
                    height: calc(100% - 55px);
                }

                @include media-breakpoint-down(sm) {
                    // height:calc(100% - 20px);
                }
            }
        }

        .product-2-col-2-row {
            grid-column: span 2;
            grid-row: span 2;

            @include media-breakpoint-down(sm) {
                grid-column: initial;
                grid-row: initial;
            }

            article {
                @include media-breakpoint-up(sm) {
                    height: calc(100% - 106px);
                }

                > div,
                .product-thumbnail {
                    @include media-breakpoint-up(sm) {
                        height: 100% !important;
                    }
                }

                .product-thumbnail img {
                    object-fit: cover;
                }
            }
        }
    }
}
