.blockcart {

  &__label,
  &__count {
    vertical-align: middle;
  }

  &__count {
    text-align: center;
    height: 21px;
    border-radius: 50%;
    font-size: 0.95rem;
    vertical-align: sub;
    float: right;
    margin-right: -3px;
    margin-top: -8px;

    @include media-breakpoint-between(sm,md) {
        right: 22px;
    }
  }

  .js-open-cart {
    &:hover {
      span{
        border-bottom: none !important;
      }
    }
  }

}