// Color scheme
// ---------------------------------------------------------------------------------------------------------------------
$pt-colors: (
        'black'      : rgb(0, 0, 0),
        'grey'       : rgb(207, 207, 207),
        'lightgrey'  : rgb(243, 243, 243),
        'white'      : rgb(255, 255, 255),
        'turquoise'  : rgb(70, 207, 200),
);

// UI
// ---------------------------------------------------------------------------------------------------------------------
$theme-title-font: 'Futura LT', Futura, sans-serif;
$theme-title-font-weight: bold;

$theme-text-font: 'Graphik', sans-serif;
$theme-text-color: map_get($pt-colors, 'black');
$theme-text-font-weight: normal;

// Overrided Bootstrap Vars
// ---------------------------------------------------------------------------------------------------------------------

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1920px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1270px
);
