.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .small-gutters {
    margin-right: 2px;
    margin-left: 2px;
    & > .col,
    & > [class*="col-"] {
      padding-right: 2px;
      padding-left: 2px;
    }
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.back-to-top {
  position: fixed;
  // right: 15px;
  right: 85px;
  bottom: 90px;
  
  width: 52px;
  height: 52px;
  z-index: 100;
  text-align: center;
  @include transform2d(translateY(195px));
  @include transition(all 450ms $easeInOutExpo);

  @include media-breakpoint-down(lg) {
    right: 15px;
  }
  
  @include media-breakpoint-up(sm) {
    width: 80px;
    height: 80px;
  }

  &.container-cta {
    margin: 0 0 20px;
    display: inline-flex;

    @include media-breakpoint-down(sm) {
      margin: 0 0 50px;
    }

    .back-to-top__icon {
      z-index: 1;
      background: map_get($pt-colors, 'white');
      border: solid 1px map_get($pt-colors, 'black');
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(all 250ms $easeInOutExpo);

      &:hover {
        transform: translate(10px, 10px);
      }
    }

    span {
      background-color: #ecb7b7;
      position: absolute;
      z-index: 0;
      right: -10px;
      bottom: -10px;
      border: 1px solid #000;
      width: 100%;
      height: 100%;
    }
  }

  &.-visible {
    @include transform2d(translateY(0));
  }

  @include link-colors(#000,#000,#000,#000,#000);

  div {
    width: 100%;
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  .icon {
    font-size: 1.8rem;
  }
}

.center-flex {
  @include display-flex;
  @include align-items(center);
  @include justify-content(center);

  & > * {
    width: 100%;
  }
}

.text-border-bottom {
  border-bottom: solid 1px;
}

.pt-monogramme {
  background: url("../img/monogramme.svg");
  background-size: 35px 30px;
  width: 35px;
  height: 30px;

  &.-x2 {
    background-size: 71px 60px;
    width: 71px;
    height: 60px;
  }
}

.copyright {
  text-align: center;
  font-size: 1.05rem;
  color: rgba(255,255,255,.85);
  padding: 0 0 10px 0;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 500;
}

body.mobilemenu-visible {
  overflow: hidden;
}