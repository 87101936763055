.banner-text {

}

.banner-cover {
  img {
    width: 100%;
    height: auto;
  }
}

.banner-footer {
  float: left;
  position: relative;

  a {
    display: inline-block;
    background: #fff;
    z-index: 1;
    position: relative;
    transition: .3s ease;
    text-transform: uppercase;

    &:hover {
    	background: #fff;
    	transform: translate(10px, 10px);
    }
  }

  span {
	background-color: rgb(236, 183, 183);
	position: absolute;
	z-index: 0;
	right: -10px;
	bottom: -10px;
	border: 1px solid #000;
	width: 100%;
	height: 100%;
  }

  &.reverse {
  	float: right;
  }
}

