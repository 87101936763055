

.featured-products {

  margin-top: -50px;
  padding: 0 0 70px;

  @include media-breakpoint-up(md) {
    margin-top: 40px;
    padding: 0 0 110px;
  }

  .products {
    width: 120%;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    padding: 0 0 10px 0;
    left: -26px;

    @include media-breakpoint-up(md) {
      max-width: 1080px;
      width: 90%;
      left: 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }

  }

  .flickity-prev-next-button {
    &.previous {
      left: -50px;
    }

    &.next {
      right: -50px;
    }
  }

  .products-wrapper {
    @include media-breakpoint-up(lg) {
      @include transform(rotate(-10.9deg))
    }
  }

  .flickity-enabled:focus { outline: none; }

  .flickity-cell {
    width: 50%;
    padding: 15px;

    @include media-breakpoint-up(md) {
      width: 25%;
    }

    img {
      position: relative;
      z-index: 2;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;

      img {
        border: solid 1px map_get($pt-colors, 'black');
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        border: solid 1px map_get($pt-colors, 'black');
        background: map_get($pt-colors, 'white');
        width: 100%;
        height: 100%;
        top: 10px;
        left: 10px;
        z-index: 1;
        opacity: 1;
        @include transition(transform 150ms $easeInOutExpo);
        @include transform2d(translateX(0px) translateY(0px));
      }

      &:hover {



        &::after {
          opacity: 0;
          @include transform2d(translateX(-10px) translateY(-10px))

        }
      }
    }

  }
}