.product-popin-alert {

  &__content {
    text-align: center;
  }

  &__title {
    display: block;
    font-size: 2.21rem;
    font-weight: 700;
    margin-block-start: .83em;
    margin-block-end: .83em;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &__button-container {
    margin-top: 20px;
    width: 100%;
    right: 0;
    position: absolute;
  }

  &__close {
    padding: 10px 40px;
    font-size: 1.2rem;
    outline: 0;
  }
}

.product-popin-alert-wrapper.fancybox-content {
  width: 90%;
  max-width: 500px;
  border: solid 1px #000;
  overflow: visible;
  position: relative;
}

#product .fancybox-bg {
  background: #fff !important;
}

.fancybox-alert-popin  {
  z-index: 99999!important;
}