.slice-section.slice-liste_de_categorie {

  margin: 10px 0 40px;

  @include media-breakpoint-up(md) {
    margin:0 0 105px 0;
  }
}

.big-cat-list {
  text-align: center;
  position: relative;

  &__item {
    position: relative;
    letter-spacing: 0.35rem;
    font-family: $theme-title-font;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    font-size: 4rem;
    margin-bottom: 4px;

    @include media-breakpoint-up(md) {
      font-size: 8rem;
      margin-bottom: 0;
    }

    a {
      display: inline-block;
      cursor: pointer;
    }

    &:hover {

      z-index: 99!important;

      a {
        background-position: 0 0;
      }
    }

  }

}

/*
body:not(.browser-safari) {
  .big-cat-list__item {
    a {
      background: -webkit-linear-gradient(
                      right,
                      rgb(255, 255, 255),
                      rgb(255, 255, 255),
                      rgb(255, 255, 255),
                      rgb(0, 0, 0),
                      rgb(0, 0, 0)
      );

      background-size: 300% 100%;
      background-position: right 0;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-transition: background 1s;
      -webkit-text-stroke: 1px map_get($pt-colors,'black');
    }

    &:hover {
      a {
        background-position: 0 0;
      }
    }
  }
}*/


.big-cat-list__item {
  a {
    color: #fff;
    @include transition(color 250ms $easeOutQuart);
    -webkit-text-stroke: 1px map_get($pt-colors,'black');
  }

  &:hover {
    a {
      color: #000;
    }
  }
}


.big-cat-image-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
}

.big-cat-image {
  background: map_get($pt-colors,'lightgrey');
  width: 240px;
  position: absolute;
  overflow: hidden;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  img {
    position: relative;
    top: 0;
    left: 0;
    opacity: 0;
  }

  img:not(:first-child) {
    position: absolute;
  }

  &.-i1 {
    left: 9%;
    top: -9%;
    z-index: 3;
    height: 273px;
    //background: #f9b960;
    background: #f2f2f2;

    img {
      @include transform2d(translateY(273px));
    }
  }

  &.-i2 {
    left: 69%;
    top: 38%;
    z-index: 5;
    height: 160px;
    //background: #e13a36;
    background: #f2f2f2;

    img {
      @include transform2d(translateY(160px));
    }
  }

  &.-i3 {
    left: 9%;
    top: 69%;
    z-index: 7;
    height: 151px;
    //background: #3b9bfa;
    background: #f2f2f2;

    img {
      @include transform2d(translateY(151px));
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

}


