.page-my-account #content .links a {
  text-align: center;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
  color: map_get($pt-colors, 'black');
  padding: 0 15px;
  margin-bottom: 15px;

  span.link-item {
    box-shadow: none;
    padding: 55px 15px;
    border: solid 1px map_get($pt-colors, 'black');
  }

  i {
    font-size: 4.5rem;
    margin-bottom: 8px;
  }

}

.links.row {
  justify-content: center!important;
}

.page-footer {
  a.account-link {
    font-size: 1.2rem;

    span {
      font-size: inherit;
    }
  }
}