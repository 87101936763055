.email-subscription {
  height: 280px;
  padding: 15px;
  background-image: url("../img/bg-email.png");
  background-position: center;
  background-size: cover;
  // background-attachment: fixed;
  color: #fff;
  margin: 30px 0;
  display: flex;
  flex-direction:column;

  @include media-breakpoint-up(sm) {
    // padding: 75px 85px;
    padding: 75px 14vw;
    margin: 0;
    height: 540px;
  }

  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }

  &__notification {
    border: solid 1px;
    display: inline-block;
    padding: 10px 25px;
    margin-bottom: 10px;

    &:empty {
      display: none;
    }
  }

  &__title {
    font-size: 1.8em;
    flex: 1;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
      font-size: 2em;
      line-height: 1.5;
      width: 80%;
    }
  }


  &__form-container {
    width: 100%;
  }

  &__legal {
    margin-top: 10px;
  }

  custom-checkbox input[type=checkbox]:focus + span,
  .custom-checkbox input[type=checkbox] + span {
    border: solid 1px #fff;
  }

  .custom-checkbox input[type=checkbox] + span .checkbox-checked {
    color: #fff;
  }

  label {
    color: #fff;
  }

}

.mcform {
  &__inputs {
    border-bottom: solid 1px map-get($pt-colors, 'white');

    input[name=email],
    button {
      height: 45px;
      vertical-align: middle;
      display: inline-block;

      @include media-breakpoint-up(sm) {
        height: 80px;
      }
    }

    input[name=email] {
      @include appearance(none);
      width: calc(100% - 84px);
      background: transparent;
      border: none;
      padding: 0;
      font-size: 1.6rem;
      color: map-get($pt-colors, 'white');
      font-weight: 300;
      line-height: 1;

      &:focus {
        outline: 0;
      }

      @include input-placeholder {
        color: map-get($pt-colors, 'white');
        opacity: 1;
      }

      @include media-breakpoint-up(sm) {
        font-size: 4rem;
      }
    }

    button {
      @include appearance(none);
      background: transparent;
      color: #fff;
      border: 0;
      border-radius: 0;
      width: 80px;
      text-align: right;
      padding: 0;
      font-size: 1.3em;

      span {
        border-bottom: solid 1px map-get($pt-colors, 'white');
      }
    }
  }
}

.mcform-checkbox-container p {
  color: map-get($pt-colors, 'white');
  margin-top: 10px;
}







