.gformbuilderpro_form {
  label {
    font-size: 1.2rem;
  }

  #textarea_message {
    height: 200px;
  }

  &.gformbuilderpro_form_1 {
    width: 70%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}


.gformbuilderpro_action {

  text-align: right;

  button {
    box-shadow: none;
    text-transform: none;
    font-weight: 400;
    color: #fff!important;
    background: #000;
    padding: 20px 40px;
    font-size: 1.2rem;
    cursor: pointer;
  }

}


.success_box {
  float: none;
  background: transparent;
  border: 0;
  padding: 0;
}

#thankyou-page {
  margin: 0 0 20px;
}

.gformbuilderpro_form #thankyou-page .alert-success {
  text-align: center;
  line-height: 1.4;
  margin-bottom: 30px;
}