.footer-link-block {
  @include reset(ul);
  margin: 0;
  padding: 15px;

  @include media-breakpoint-up(lg) {
    padding: 68px;
  }

  li + li {
    margin-top: 8px;

    @include media-breakpoint-up(sm) {
      margin-top: 10px;

    }
  }
}

.footer-signature {
  width: 100%;
  text-align: center;

  padding: 10px;

  @include media-breakpoint-up(sm) {
    padding: 0;
  }

  svg {

    width: 80px;

    @include media-breakpoint-up(sm) {
      width: 120px;
    }
  }
}

.footer-section {

  font-size: 1.2rem;

  a {
    @include link-colors(
                    map-get($pt-colors, 'black'),
                    map-get($pt-colors, 'black'),
                    map-get($pt-colors, 'black'),
                    map-get($pt-colors, 'black'),
                    map-get($pt-colors, 'black')
    );

    &:hover {
      border-bottom: solid 1px;
    }
  }

  .col-sm + .col-sm {
    border-top: solid 1px map-get($pt-colors, 'black');

    @include media-breakpoint-up(sm) {
      border-top: 0;
    }
  }

  &.-with-border {
    border-top: solid 1px map-get($pt-colors, 'black');
    border-bottom: solid 1px map-get($pt-colors, 'black');
    @include media-breakpoint-down(md) {
      border: solid 1px map-get($pt-colors, 'black');
    }

    .col-sm + .col-sm {
      @include media-breakpoint-up(sm) {
        border-left: solid 1px map-get($pt-colors, 'black');
      }
    }
  }

  .col-sm {
    @extend .center-flex;
  }


  ul {
    @extend .footer-link-block
  }
}

#footer {
  padding-top: 0;
  margin-top: 20px;

  @include media-breakpoint-up(sm) {
    margin-top: 60px;
  }
}