
.slice-carousel {
  position: relative;

  /*&:::after {
    content: '';
    display: block;
    width: 220px;
    height: 100%;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    pointer-events: none;
  }*/

   & > .container {
     @include media-breakpoint-down(md) {
       padding-left: 0;
       padding-right: 0;
     }
   }
}

.carousel-slider {

  &.-mobile {

    .carousel-slider-item {
      width: 75%;
      height: 100%;
      padding: 0 7px;
    }

    .flickity-page-dots {
      position: static;
      padding: 10px 0 0 0;

      .dot {
        background: #fff;
        opacity: 1;
        border: solid 1px #000;

        &.is-selected {
          border: solid 1px #000;
          background: #000;
        }
      }

    }

  }

  &.-desktop {
    position: relative;
    width: 100%;
    height: 705px;
    z-index: 10;

    .carousel-slider-item {
      width: 705px;
      height: 705px;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;

      padding: 40px;

      @include transform2d(translateX(9999px) translateY(0px) );

      &:nth-child(1) {
        @include transform2d(translateX(0px) translateY(-109px) );
        display: block;
      }

      &:nth-child(2) {
        @include transform2d(translateX(705px) translateY(0px) );
        display: block;
      }

      &:nth-child(3) {
        @include transform2d(translateX(1410px) translateY(0px));
        display: block;
      }

      &:nth-child(4) {
        @include transform2d(translateX(2115px) translateY(0px));
        display: block;
      }

      img {
        cursor: pointer;
      }

    }
  }

}


.carousel-tooltip {

  display: none;

  @include media-breakpoint-up(md) {
    z-index:100;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none; /* Very important to prevent the follower from messing up with the mouse move */
    opacity: 0;
    display: block;

    &.-visible {
      opacity: 1;
    }

  }
}

.carousel-pan {

  //display: none;

  @include media-breakpoint-up(md) {
    //background: rgba(209, 70, 85, 0.52);
    min-width: 150px;
    width: 14%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 11;
    cursor: pointer;

    &.-left {
      left: 0;
    }

    &.-right {
      right: 0;
    }
  }

}