/*.header #search_widget {

  form > * {
    vertical-align: middle;
  }

  input[name=s] {
    @include appearance(none);
    // border: 0;
    // padding: 0;
    // width: 80%;
    // font-size: 1.2rem;
    // margin-left: 14px;

    @include input-placeholder {
      color: $theme-text-color;
      font-weight: $theme-text-font-weight;
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }
  }

  .icon {
    font-size: 1.75rem;
    position: relative;
    top: -1px;
  }
}

.page-not-found #search_widget {

  input[name=s],
  button {
    @include appearance(none);
    height: 45px;
    vertical-align: middle;
    border: solid 1px #000;
    padding: 0 13px;
  }

  input[name=s] {
    border: solid 1px #000;
    border-right:0;
    width: 70%;
    max-width: 400px;
    font-size: 1.4rem;
    margin-left: 14px;

    @include input-placeholder {
      color: $theme-text-color;
      font-weight: $theme-text-font-weight;
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }
  }

  button {
    display: inline-block!important;
    background: transparent;
    font-size: 1.4rem;
  }

  .icon {
    display: none;
  }

}


#search_widget input[type=text] {
  width: 15px;
  padding-left: 10px;
  color: transparent;
  cursor: pointer;
  background: #fff url('../img/search.svg') no-repeat 9px center;
  padding: 9px 10px 9px 32px;
  width: 67%;
  margin-left: 19px;
  border: 0;
  padding: 3px 0 0;
  color: #000;
  text-indent: 30px;

  // @include media-breakpoint-down(sm) {
  //   border: 1px solid #000;
  //   margin-left: 33px;
  // }
  
  // -webkit-transition: all .5s;
  // -moz-transition: all .5s;
  // transition: all .5s;
}

.mobilesearch {
  
  @include media-breakpoint-down(sm) {
    border: none !important;
  }

  .button {
    @include media-breakpoint-down(sm) {
      width: 40px;
    }
  }
}
*/