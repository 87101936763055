.slice-produits {

  h2 {
    color: white;
    font-size: 6rem;
    letter-spacing: 0.35rem;
    font-family: "Futura LT", Futura, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    margin: -80px 0 75px 0;
    -webkit-text-stroke: 1px #000;
    -webkit-user-select: none;
    -webkit-transform: skew(0deg, -10deg);
    -moz-transform: skew(0deg, -10deg);
    -ms-transform: skew(0deg, -10deg);
    -o-transform: skew(0deg, -10deg);
    transform: skew(0deg, -10deg);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    z-index: 10;
    left: 30px;

    @include media-breakpoint-down(md) {
      margin: 0 0 55px 0;
    }

    @include media-breakpoint-down(sm) {
      margin: 0;
      left: 6px;
      font-size: 3rem;
    }
  }

  .item {
    padding-bottom: 40px;
    border-bottom: 1px solid;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }

  img {
    width: 100%;
  }

  p{
    margin: 20px 0 0;
    font-size: 1.2rem !important;

    @include media-breakpoint-down(sm) {
      margin:10px 0 0;
    }

    span {
      display: block;

      &.title {
        float: left;
        font-weight: 600;
      }

      &.price {
        float: right;
      }
    }
  }

  .container-cta {
    position: relative;
    margin: 0 0 20px;
    display: inline-flex;

    @include media-breakpoint-down(sm) {
      margin: 0 0 50px;
    }

    a {
      position: relative;
      z-index: 1;
      background: #fff;
      text-transform: uppercase;

      &:hover {
        transform: translate(10px, 10px);
      }
    }

    span {
      background-color: #ecb7b7;
      position: absolute;
      z-index: 0;
      right: -10px;
      bottom: -10px;
      border: 1px solid #000;
      width: 100%;
      height: 100%;
    }
  }

}