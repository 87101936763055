// Graphik
// ---------------------------------------------------------------------------------------------------------------------

/*
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Extralight.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}*/

/*@font-face {
  font-family: 'Graphik';
  src: local('Graphik'), url('../fonts/graphik/Graphik-RegularItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}*/

/*@font-face {
  font-family: 'Graphik';
  src: local('Graphik'), url('../fonts/graphik/Graphik-SemiboldItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}*/

/*
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-BlackItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}*/

/*
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Thin.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}*/

/*@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-BoldItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}*/

/*
@font-face {
  font-family: 'Graphik Super';
  src: url('../fonts/graphik/Graphik-SuperItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-SuperItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}*/

/*
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-ExtralightItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}*/

/*@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-LightItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}*/

/*
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-ThinItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}*/

/*@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-MediumItalic.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}*/

/*
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Black.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}*/


/*
@font-face {
  font-family: 'Graphik Super';
  src: url('../fonts/graphik/Graphik-Super.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Super.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}*/

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Light.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Regular.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Semibold.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/graphik/Graphik-Bold.woff2') format('woff2'),
  url('../fonts/graphik/Graphik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

// Futura
// ---------------------------------------------------------------------------------------------------------------------

@font-face {
  font-family: 'Futura LT';
  src: url('../fonts/futura/FuturaLT-Bold.woff2') format('woff2'),
  url('../fonts/futura/FuturaLT-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

