// Explanation text
// ---------------------------------------------------------------------------------------------------------------------
.card-list-explain-text{
    font-family: $theme-text-font;
    font-weight: $theme-text-font-weight;
    font-size: 1.4rem;
}

// Description
// ---------------------------------------------------------------------------------------------------------------------
.card-list-description{
    font-family: $theme-title-font;
    font-weight: $theme-title-font-weight;
    font-size: 2.21rem;
}

// Card list
// ---------------------------------------------------------------------------------------------------------------------
#card-list{
    th{
        background-color: #fff;
        font-family: $theme-title-font;
        font-weight: $theme-title-font-weight;
        color: map_get($pt-colors, 'black');
        border: 1px solid map_get($pt-colors, 'black');
    }
    tr{
        background-color: #fff;
    }
    td{
        border: 1px solid map_get($pt-colors, 'black');
    }
}

// Card list responsive
// ---------------------------------------------------------------------------------------------------------------------
.card-list-mobile{
    .card-list-mobile-item{
        border: 1px solid map_get($pt-colors, 'black');
        width: 100%;
        margin-bottom: 10px;
        ul{
            li{
                padding: 5px 5px;
                display: flex;
                justify-content: space-between;
                // Delete link on mobile
                .ppdeletecard-mobile{
                    color: map_get($pt-colors, 'orange');
                }
            }
        }
    }
}