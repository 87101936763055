.hero-wrapper {
  height: 400px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 85vh;
    min-height: 850px;
  }
}

.hero {

  text-align: center;
  position: relative;
  z-index: 10;

  &__title {
    color: map_get($pt-colors,'black');
    font-size: 3rem;
    font-family: $theme-title-font;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: 8rem;
      letter-spacing: 0.35rem;
    }
  }

  &__footer {
    margin-top: 45px;
  }
}

.hero-image,
.hero-square,
.hero-shape {
  position: absolute;
}

.hero-image {
  left: 0;
  height: 230px;

  @include media-breakpoint-up(md) {
    height: 750px;
  }
}

.hero-square {
  right: 0;
  width: 130px;
  height: 130px;
  z-index: 1;
  top: calc(50% - 60px);

  @include media-breakpoint-up(md) {
    width: 550px;
    height: 550px;
    top: calc(50% - 275px);
  }
}

.hero-shape {

  &.-circle {
    background: url("../img/hero-circle.png");
    width: 140px;
    height: 140px;

    background-size: cover;
    z-index: 2;
    right: -100px;
    top: 20px;

    @include media-breakpoint-up(md) {
      width: 240px;
      height: 240px;
      right: 110px;
      top: 50px;
    }
  }

  &.-rectangle {
    background: url("../img/hero-rectangle.png");
    width: 140px;
    height: 140px;
    background-size: cover;
    z-index: 2;
    left: -90px;
    top: 280px;


    @include media-breakpoint-up(md) {
      width: 298px;
      height: 297px;
      left: -130px;
      top: 440px;
    }
  }

}