.slice-produit_mis_en_avant .container {
  position: relative;
}

.product-featured-single {
  &__image {
    height: auto;
    max-height: 800px;
    background: map_get($pt-colors,'lightgrey');
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    a {
      height: 100%;
    }

    @include media-breakpoint-up(md) {
      height: 80vh;
      max-height: 800px;
      margin-bottom: 0;

      a {
        height: 100%;

        img {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}

.product-featured-header {

  @include media-breakpoint-up(md) {
    position: absolute;
    left: 60px;
    bottom: auto;
    top: calc(50% - 82px);
  }

  &__metas {

    .infobox__wrapper {
      position: relative;
      top: -1px;
    }

    @include media-breakpoint-up(md) {

      margin-left: 40px;



    }
  }
}