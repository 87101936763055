.section-full-width {
	position: relative;
}

.media_full_width {
	img {
		width: 100%;
	}

	.youtube {
	    margin-bottom: 30px;
	    position: relative;
	    padding-top: 35.25%;
	    overflow: hidden;
	    cursor: pointer;
	    width: 100%;
	    background-image: none;
	    height: 300px;
	    background-size:cover !important;

	    .shadow {
	    	position:absolute;
	    	width: 100%;
	    	height: 100%;
	    	background: rgba(0,0,0,.4);
	    	z-index: 1;
	    	top: 0;
	    }

	    .play-button {
	    	width: 55px;
		    height: 55px;
		    z-index: 2;
		    border-radius: 35px;
		    cursor: pointer;
		    position: absolute;
		    top: 50%;
    		left: 50%;
    		transform: translate(-50%,-50%);
    		background: #fff;

    		span {
    			display: inline-block;
			 	height: 0;
			 	width: 0;
			 	border-top : 10px solid transparent;
			 	border-bottom : 12px solid transparent;
			 	border-left : 19px solid black;
			 	position: absolute;
    			top: 17px;
    			left: 21px;
    		}
	    }

	    img {
	    	position: absolute;
	    	cursor: pointer;
	    	width: 100%;
		    top: -16.84%;
		    left: 0;
		    opacity: 0.7;
	    }

	    iframe {
	    	height: 100%;
		    width: 100%;
		    top: 0;
		    left: 0;
		    position:absolute;
	    }
	}
}