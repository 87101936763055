.store-footer-wrapper {
  background: #afdae2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: map_get($pt-colors, 'white');
  margin-top: 30px;
  min-height: 0;
  padding: 20px 0;

  @include media-breakpoint-up(md) {
    min-height: 550px;
    padding: 0;
  }


  @include media-breakpoint-up(lg) {
    min-height: 550px;
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .store-footer {

    text-align: center;

    &__name {
      text-transform: uppercase;
      margin-bottom: 10px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 35px;
      }
    }

    &__address {
      font-size: 2rem;
      line-height: 1;
      font-weight: 300;
      margin-bottom: 35px;

      @include media-breakpoint-up(md) {
        font-size: 5rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 7.5rem;
      }

      .street-address {
        display: block;
      }
    }

    &__opening-hours {
      position: relative;
      max-width: 460px;
      width: 100%;
      margin: 0 auto;

      ul li + li {
        margin-top: 6px;
      }

      .current-weekday {
        border-bottom: solid 1px map_get($pt-colors, 'white');
      }

      .store-is-open {
        height: 100%;
      }

    }
  }
}

