$header-section-height: 70px;
$header-margin: 45px;
$header-z-index: 100;

// Non mobile header
// ---------------------------------------------------------------------------------------------------------------------

#header {
    z-index: $header-z-index;
    min-height: 51px;
    position: sticky;
    top: 0;
    z-index: 100;

    @media (min-width: 1081px) {
        min-height: 70px;
    }

    a:hover span {
        border-bottom-width: 0!important;
    }

    .header {
        display: flex;
        background: #fff;
        border-bottom: solid 1px #000;
    }

    /* .header {
        color: $theme-text-color;
        position: relative;
        font-size: 1.2rem;
        z-index: $header-z-index;

        &-top,
        &-bottom {
            height: $header-section-height;
            padding-left: $header-margin;
            background: map_get($pt-colors, "white");
            border: solid 1px map_get($pt-colors, "black");
            z-index: 100;
            margin-top: -1px;
            transform-origin: center center;
        }

        // &__languages,
        // &__search,
        // &__account,
        // &__cart {
        //   @extend .align-self-center;
        // }

        &__search {
            overflow: hidden;
            cursor: pointer;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            transition: all 0.5s;

            &.search-active {
                flex: 0 0 76%;
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;
                transition: all 0.5s;
            }
        }

        &__languages,
        &__account,
        &__search,
        &__cart,
        &-mobile__menu {
            flex: 0 0 80px;
            text-align: center;
            border-right: 1px solid #000;
            padding: 29px 0;
        }

        &__logo-wrapper {
            flex: 1;
        }

        &__nav {
            overflow: hidden;
            display: flex;
            // flex: 1 1 100%;
            flex-flow: row-reverse;
            & > div {
            }
        }

        &__languages {
            padding: 31px 0;

            ul {
                text-transform: uppercase;
                @include inline-block-list();

                li.current {
                    span {
                        @extend .text-border-bottom;
                    }
                }

                li + li {
                    &::before {
                        content: "";
                        display: inline-block;
                        padding: 0 3px;
                    }
                }
            }
        }

        &__cart {
            a {
                display: block;
                height: 18px;
                width: 18px;
                margin: 0 auto;

                img {
                    width: 18px;
                }
            }
        }

        &__account {
            a.login,
            a.account {
                img {
                    width: 20px;
                }
            }
        }
    }

    */

    .header-logo {
        width: 160px;
        @extend .align-self-center;
        @include transform2d(translateX(-46px));
        @include transition(all 450ms 200ms $easeInOutExpo);

        -webkit-clip-path: polygon(22% 0, 100% 0, 100% 100%, 22% 100%);
        clip-path: polygon(22% 0, 100% 0, 100% 100%, 22% 100%);
    }

    .header-top {
        .header {
            &__languages,
            &__account,
            &__cart {
                flex: 0 0 auto;
                text-align: center;
                padding: 0 20px;
            }
        }
    }

    // Sticky behavior
    .header-bottom {
        overflow-x: hidden;
        &.-stuck {
            .header-logo {
                @include transform2d(translateX(0px));
                -webkit-clip-path: polygon(0% 0, 26% 0, 22% 100%, 0% 100%);
                clip-path: polygon(0% 0, 22% 0, 26% 100%, 0% 100%);
            }
        }
    }
}

@media (min-width: 1150px) {
    #header .header-mobile__menu {
        display: none;
    }

    #header .header-mobile__cart {
        border-right: none !important;
    }
}

// Layout for specific pages
// ---------------------------------------------------------------------------------------------------------------------

    #header {
        @media (min-width: 1081px) {
            position: sticky;
            width: 100%;
            top: 0;
        }
    }


// Mobile header
// ---------------------------------------------------------------------------------------------------------------------

#header {
    .header-mobile-wrapper {
        position: fixed;
        width: 100vw;
        z-index: 99;
        top: 15px;
    }

    /*.header-mobile {
        background: map_get($pt-colors, "white");
        border: solid 1px map_get($pt-colors, "black");
        height: 52px;

        display: flex;
        align-items: center;

        &__logo {
            flex: 1;
            height: 27px;
            padding: 0 10px;

            a {
                display: block;
            }

            svg {
                height: 27px;
            }
        }

        &__menu {
            @include media-breakpoint-down(sm) {
                margin-right: -1px;
            }
        }

        &__nav {
            display: grid;
            grid-template-columns: repeat(4, 50px);
            grid-template-rows: 1fr;
            align-self: stretch;

            & > div {
                padding: 0 10px;
                height: 100%;
                border-left: solid 1px map_get($pt-colors, "black");
                flex: 1;

                @extend .center-flex;

                img {
                    width: 15px;
                    margin: 0 auto;
                    display: block;
                }
            }

            svg {
                height: 15px;
            }
        }
    }*/
}

// Header V2
// ---------------------------------------------------------------------------------------------------------------------

.header__menu {
    width: 319px;

    @media (max-width: 1150px) {
        width: 400px;
    }

    > div:last-child {
        border-right: none !important;
    }
}


.header-pt-logo-inner {
    width: 132px;
    position: relative;
    top: 3px;
}