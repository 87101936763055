

// Formatted items
// ---------------------------------------------------------------------------------------------------------------------

.dp-formatted-items {
  font-size: 11px;
  margin-bottom: 10px ;
}

.dp-formatted-items ul ul {
  list-style-type: none;
  color: #666;
  margin-top: 5px;
}