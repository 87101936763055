.slice-banner_with_caption > .container {
  position: relative;
}

.banner-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  position: relative;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    // padding: 85px;
    padding: 150px 85px;
  }

  &__monogramme {

    svg {
      display: block;
      margin: 0 auto;
      width: 35px;
      fill: #fff;
    }

  }

  &__description,
  &__title {
    text-align: center;
  }

  &__title {
    h2 {
      font-size: 3.5rem;
      margin-block-start: .43em;
      margin-block-end: .43em;

      @include media-breakpoint-up(md) {
        font-size: 4rem;
        margin-block-start: .83em;
        margin-block-end: .83em;
      }
    }
  }

  &__description {
    p {
      color: inherit;
      font-size: 1.45rem;
      line-height: 1.55;
      text-align: justify;
      text-align-last: center;
    }
  }

  &__footer {
    margin-top: 25px;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 45px;
    }

    a {
      @extend .colored-button;
    }
  }
}

.banner-image {
  position: absolute;
  z-index: 1;
  height: 400px;
  top: calc(50% - 200px);
  // right: 50px;
  left: 120px;
  @include transform2d(rotate(-12deg));
}

.banner-image-mobile {
  margin: 0 0 20px 0;
}

.slice-banner {
  .container-text {
    border: 1px solid;
    padding: 45px 45px 70px;
    max-height: 100%;
    height: 100%;

    @include media-breakpoint-down(md) {
      padding: 0px 30px 30px;
    }

    > div {
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 85%;
      left: 50%;

      @include media-breakpoint-down(md) {
        position: inherit;
        top: inherit;
        transform: inherit;
        width: inherit;
        left: inherit;
      }
    }

    p {
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    h2{
      font-size: 2.3rem;
    }
  }

  .style_2_3 {
    .container-text {
      height: 100%;
      border: none;

      @include media-breakpoint-down(lg) {
        padding: 45px 45px 70px !important;
      }

      > div {
        @include media-breakpoint-down(lg) {
          width: 100% !important;
          position: initial !important;
          transform: initial !important;
        }
      }

      p{
        @include media-breakpoint-down(md) {
          font-size: 1.2rem !important;
        }
      }

      h2 {
        font-family: "Graphik", Futura, sans-serif;
        font-weight: 600;
        letter-spacing: 2.5px;
      }
    }

    .col-sm-8{
      padding-left: 0px;
      &.order-first {
        padding-right: 0;
        padding-left: 15px;
      }
    
      @include media-breakpoint-down(sm) {
        padding: 0 15px !important;
      }

      &.order-sm-12 {
        padding-right: 15px;
        padding-left: 0;
      }
    }
    .col-sm-4{
      padding-left: 0;

      &.order-first {
        padding-left: 15px;
        padding-right: 0px;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 15px !important;
      }

      &.reverse {
        padding-left: 15px;
        padding-right: 0;
      }

      .container-text {

        @include media-breakpoint-down(sm) {
          padding: 10px 25px 40px;
          max-height: 100%;
        }

        @include media-breakpoint-down(md) {
          padding: 0px 13px 35px;
        }

        > div {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          width: 70%;

          @include media-breakpoint-down(md) {
            position: inherit;
            width: 100%;
            transform: inherit;
            top: inherit;
            left: inherit;
          }
        }
      }

      p{
        color: inherit;
      }
    }
  }

  .banner-cover {
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .banner-footer {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      margin-top: 5px;
    }
  }
}