.product-miniature {

  position: relative;

  .variant-links {
    display: none;
  }

  .product-configurable-notice {
    position: absolute;
    right: 20px;
    top: 17px;

    &__text {
      display: none;
    }
  }

  &:hover {
    .product-configurable-notice__text {
      display: block;
    }
  }

  .product-thumbnail-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .product-thumbnail {
    display: block;
    overflow: auto;
    flex: 1;
    background: map_get($pt-colors,'lightgrey');
    height: auto !important;

    img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }

    // Handle the lazy loading rendering
    .lazy {
      opacity: 0;
      @include transition(opacity 250ms $easeOutExpo);

      &.loaded {
        opacity: 1;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 393.33px;
    }

    @include media-breakpoint-up(xxl) {
      height: 400px;
    }
  }

  .product-description {
    border-bottom: solid 1px map_get($pt-colors,'black');
    padding: 10px 0;

    @include media-breakpoint-up(sm) {
      display: flex;
      padding: 20px 0;
    }

    .product-title {
      flex: 1;
      margin-bottom: 3px;
    }

    h2.product-title,
    a {
      font-size: 1.3rem;
      color: map_get($pt-colors,'black');
      display: block;
      line-height: normal;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-up(sm) {
        height: 23px;
        overflow: auto;
      }
    }
  }

  .product-price-and-shipping {
    text-align: left;
    font-size: 1.3rem;
    line-height: 14px;

    @include media-breakpoint-up(md) {
      text-align: right;
    }

    // &::before {
    //   content: '/';
    //   display: none;

    //   @include media-breakpoint-up(sm) {
    //     display: inline-block;
    //   }
    // }

    .price,
    .discount-product {
      font-size: 1.3rem;
    }

    .regular-price {
      font-size: 1.1rem!important;
      margin: 0;

      @include media-breakpoint-up(sm) {
        margin: 0 4px;
      }
    }

    .discount-product {
      display: none;
    }
  }

}


body {
  #products,
  .category-featured-products,
  .featured-products,
  .slice-product,
  .product-accessories {
    .product-miniature {

      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 55px;
      }

      .product-title a {
        font-size: 1.3rem;
        margin-bottom: 4px;
        font-weight: bold;

        @include media-breakpoint-up(sm) {
          margin-bottom: 0;
        }
      }

      .highlighted-informations {
        position: absolute;
        width: 100%;
        bottom: 100px;

        .product-quickview-button {
          background: map_get($pt-colors,'white');
          border: solid 1px map_get($pt-colors,'black');
          color: map_get($pt-colors,'black');
          font-size: 1.4rem;
          padding: 11px 0;
          max-width: 180px;
          width: 100%;
          display: block;
          margin: 0 auto;
          text-align: center;
          opacity: 0;
          @include transform2d(translateY(-20px) scale(0.9));
          @include transition(all 180ms $easeInOutExpo);

          span {
            @extend .text-border-bottom
          }
        }
      }

      &:hover {
        .highlighted-informations {
          .product-quickview-button {
            opacity: 1;
            @include transform2d(translateY(0px)  scale(1));
          }
        }
      }

      .product-flags {
        position: absolute;
        top: 5px;
        left: auto;
        right: 5px;
        z-index: 1;
        margin: 0;
        padding: 0;

       /* @include media-breakpoint-up(md) {
          top: 20px;
          right: 20px;
        }*/

        @include media-breakpoint-up(md) {
          right: auto;
          left: 20px;
          top: 20px;
        }


        & > li {
          display: inline-block;
          color: map_get($pt-colors, 'black');
          background: map_get($pt-colors, 'white');
          border: solid 1px map_get($pt-colors, 'black');
          font-size: 1.15rem;
          padding: 2px 5px!important;

          text-transform: uppercase!important;
          line-height: 1!important;
          font-weight: 400!important;

          @include media-breakpoint-up(md) {
            padding: 4px 9px;
          }
        }
      }
    }
  }



}

.product-configurable-notice {
  display: flex;
  align-items: center;

  &__icon {
    background: url('../img/icon-configurable.png');
    width: 25px;
    height: 22px;
    background-size: cover;
  }

  &__text {
    font-size: 11px;
    color: #000;
    margin-right: 10px;
  }
}

