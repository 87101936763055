.gallery-item {
  display: block;
  margin-bottom: 30px;
  @include transition(all 150ms $easeInSine);

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background: #eee;
  }

  &:hover {
    opacity: 0.80;
  }
}

.gallery-wrapper {
  margin-bottom: -30px;
}

/*
.gallery-wrapper .row > *:nth-child(odd) {
  .gallery-item {
    position: relative;
    top: 10px;
  }
}

.gallery-wrapper .row > *:nth-child(even) {
  .gallery-item {
    position: relative;
    top: -10px;
  }
}*/