.product-tags {
  margin: 10px 0;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(md) {
    margin: 40px 0;
  }

  &__title {
    font-size: 1.2rem;
  }

  ul {
    flex: 1;
    margin: 0 0 0 20px;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 0;

      border: solid 1px transparent;

      &:hover:not(.current) {
        border: solid 1px #ccc;
      }

      &.current {
        border: solid 1px map_get($pt-colors, 'black');
      }

      img {
        width: 43px;
        height: 43px;
        object-fit: cover;
      }
    }
  }
}
