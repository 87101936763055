.about {
  height: 450px;
  background-image: url("../img/bg-about.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 80vh;
    max-height: 800px;
  }

  &__background-text {
    @extend .text-outline;
    line-height: 1;
    text-align: center;
    height: 140px;
    position: absolute;
    top: calc(50% - 160px);
    width: 100%;
    font-size: 8rem;

    @include media-breakpoint-up(md) {
      top: calc(50% - 70px);
      font-size: 15.4rem;
    }
  }

  &__entry {
    width: 190px;
    height: 190px;
    background: map_get($pt-colors, 'white');
    border: solid 1px map_get($pt-colors, 'black');
    position: absolute;
    left: calc(50% - 95px);
    top: calc(50% - 95px);

    @include media-breakpoint-up(md) {
      top: calc(50% - 95px);
    }

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 120px;
    }
  }
}

.container-about {
  @include media-breakpoint-down(md) {
    padding-right: 0;
    padding-left: 0;
  }
}