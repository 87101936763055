$ps-text-color: #000;
$ps-background-color: #fff;

body,
#header {
  color: $ps-text-color;
  background: transparent;
}

.footer-container,
.carousel,
#wrapper {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

#header {
  .header-top,
  .header-bottom {
    padding: 0;
  }
}

hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}

#main {
  .page-content,
  .page-header {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: 0;
  }
}

#products,
.featured-products,
.product-accessories,
.product-miniature {

  .product-miniature {
    margin: 0;
    padding: 0;

    img {
      margin: 0;
    }
  }

  .thumbnail-container {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    background: transparent;
    box-shadow: none;
  }
}

.block-contact {
  border: 0;
  padding: 0;
  margin: 0;
}

.block-categories,
.block-category {
  min-height: auto;
  margin: 0;
}

#search_filters_brands,
#search_filters_suppliers,
#search_filters,
.block-categories {
  box-shadow: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

#products,
.featured-products,
.product-accessories,
.product-miniature {
  .product-title {
    margin: 0;
    text-align: left;
    text-transform: none;

    a {
      color: $ps-text-color;
      font-size: inherit;
      text-decoration: none;
      text-align: left;
      font-weight: normal;
    }
  }

  .highlighted-informations {
    position: static;
    bottom: 0;
    padding-top: 0;
    z-index: 0;
    background: transparent;
    text-align: left;
    width: auto;
    height: auto;
    box-shadow: none;
    transition: none;
  }

  .product-description {
    position: static;
    background: transparent;
    width: auto;
    bottom: 0;
    height: auto;
  }

  .product-price-and-shipping {
    color: $ps-text-color;
    font-weight: normal;
    text-align: left;
  }

  img {
    margin-left: 0px;
  }

  .regular-price {
    color: $ps-text-color;
    text-decoration: line-through;
    font-size: 1.4rem;
  }
}

.featured-products .products,
.product-accessories .products,
.product-miniature .products {
  display: block;
}

.product-flags {
  position: static;
  top: 0;
  width: auto;
}

li.product-flag {
  display: block;
  position: static;
  background: transparent;
  font-weight: normal;
  padding: 0px;
  text-transform: none;
  color: $ps-text-color;
  margin-top: 0;
  z-index: 2;
  box-shadow:none;
}

h1 {
  display: block;
  font-size: 2.94rem;
  font-weight: bold;
  margin-block-start: .67em;
  margin-block-end: .67em;
}

h2 {
  display: block;
  font-size: 2.21rem;
  font-weight: bold;
  margin-block-start: .83em;
  margin-block-end: .83em;
}

h3 {
  display: block;
  font-size: 1.73rem;
  font-weight: bold;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

h4 {
  display: block;
  font-size: 1.48rem;
  font-weight: bold;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

h5 {
  display: block;
  font-size: 1.23rem;
  font-weight: bold;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

h6 {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

#products,
.featured-products,
.product-accessories {
  .product-miniature {
    margin: 0;
    .discount {
      display: none;
    }
    .product-flags .new,
    .online-only,
    .on-sale,
    .discount-percentage,
    .pack {
      display: block;
      position: static;
      left: 0;
      padding: 0;
      color: #fff;
      background: #000;
      text-transform: none;
      min-width: 0;
      min-height: 0;
      font-weight: normal;
      box-shadow: none;
      &.discount-percentage {
        z-index: 2;
      }
      &.on-sale {
        width: 100%;
        text-align: center;
        left: 0;
        top: 0;
      }
      &.online-only {
        margin: 0;
        &::before {
          display: none;
        }
      }
    }
  }
}

