// Variables
// ---------------------------------------------------------------------------------------------------------------------
@import "_partials/fonts";
@import "_partials/variables";

@import "_components/simplebar";

// Bootstrap
// ---------------------------------------------------------------------------------------------------------------------
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/nav";

// Compass Mixins (Compass & core)
// ---------------------------------------------------------------------------------------------------------------------
@import "~compass-mixins";
@import "_partials/mixins";
@import "_components/flickity";

// Reset PS Theme
// ---------------------------------------------------------------------------------------------------------------------
@import "_partials/reset-ps";

// Fancybox
// ---------------------------------------------------------------------------------------------------------------------
@import url("https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.6/dist/jquery.fancybox.min.css");

// Core
// ---------------------------------------------------------------------------------------------------------------------
@import "_partials/helpers";
@import "_partials/layout";
@import "_partials/sprites";
@import "_partials/forms";
@import "_partials/plyr";

// Components
// ---------------------------------------------------------------------------------------------------------------------
@import "_components/generic/cta";
@import "_components/generic/richtext";
@import "_components/generic/banner";
@import "_components/generic/video";
@import "_components/generic/gallery";
@import "_components/header";
@import "_components/header-pt";
@import "_components/footer";
@import "_components/breadcrumb";
@import "_components/infobox";
@import "_components/homecarousel";
@import "_components/bigcategorylist";
@import "_components/categoryfeatured";
@import "_components/productfeaturedsingle";
@import "_components/hero";
@import "_components/bannercaption";
@import "_components/imagetext";
@import "_components/about";
@import "_components/storefooter";
@import "_components/pushedito";
@import "_components/modal";
@import "_components/quickview";
@import "_components/media_full_width";
@import "_components/prismic_produits";
@import "_components/prismic_categories";
@import "_components/prismic_three_columns";
@import "_components/topheader";
@import "_components/cart-bar";
@import "_components/popin-alert";

// Templates
// ---------------------------------------------------------------------------------------------------------------------
@import "templates/pages/stores";
@import "templates/pages/page";
@import "templates/pages/sitemap";
@import "templates/pages/cms";
@import "templates/pages/contactform";
@import "templates/catalog/product-list";
@import "templates/catalog/product";
@import "templates/catalog/_partials/product";
@import "templates/catalog/_partials/product-customization";
@import "templates/customer/customer";

// Modules
// ---------------------------------------------------------------------------------------------------------------------
@import "_modules/ps_searchbar";
@import "_modules/w3shoppingcart";
@import "_modules/ps_productsfeatured";
@import "_modules/w3emailsubscription";
@import "_modules/w3instagrampix";
@import "_modules/blockreassurance";
@import "_modules/contactform";
@import "_modules/pm_advancedsearch4";
@import "_modules/w3productsmatch";
@import "_modules/w3productstags";
@import "_modules/payplug";
@import "_modules/ps_shoppingcart";
@import "_modules/gformbuilder";
@import "_modules/dynamicproduct";

//Trustpilot
@import "_modules/trustpilot";

// Studio
// ---------------------------------------------------------------------------------------------------------------------
@import "_components/studio";

// Notifications
// ---------------------------------------------------------------------------------------------------------------------

.alert {
    font-size: 1.2rem;

    ul {
        margin-bottom: 0;
    }

    &.alert-info,
    &.alert-warning {
        background: #fff;
        border: solid 1px #000;
        color: #000;
    }
}

// Cart, checkout & customer account
// ---------------------------------------------------------------------------------------------------------------------

/* Cart and customer account can be customized in this file because it is not excluded from cart and sidebar */

@import "_modules/w3checkout";
@import "_modules/w3customeraccount";

.new-website {
    border: solid 1px #000;
    padding: 1.25rem;
    margin: 20px 0;

    &__title {
        font-family: $theme-title-font;
        font-weight: $theme-title-font-weight;
        font-size: 1.5rem;
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    a {
        @extend .text-border-bottom;
        padding-bottom: 3px;
    }
}

.login-form-header {
    font-size: 4rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
}

// Fix
// ---------------------------------------------------------------------------------------------------------------------

#index {
    .slice-blocs_de_texte_avec_image {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

// Fix configurateur
// ---------------------------------------------------------------------------------------------------------------------

#studioframe label {
    text-align: center;
}

.fancybox-active {
    overflow: hidden !important;
}

.fancybox-active #Smallchat {
    display: none !important;
}

body .fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 0;
    right: auto;
    top: 11px;
    opacity: 0;
    width: 160px;
    height: 40px;
}

// Fix order confirmation
#order-confirmation {
    background: white;
    .orderconf-logo {
        margin: 25px 0px;
    }
    #confirmation-wrapper {
        #content-hook_order_confirmation {
            margin-top: 100px;
        }
    }
}

.order-confirmation-table,
#order-products {
    .dp_input_div {
        display: block!important;
    }

    .dp-modify-item {
        display: none!important;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Bootstrap fix
// ---------------------------------------------------------------------------------------------------------------------

[class*="col-"] {
    float: none!important;
}