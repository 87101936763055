// Body
// ---------------------------------------------------------------------------------------------------------------------

html {
    font-size: 0.625em; /* fallback IE8+ */
    font-size: calc(
        1em * 0.625
    ); /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
}
body {
    font-size: 1.4em;
    scroll-behavior: smooth;
}

body {
    font-family: $theme-text-font;
    color: $theme-text-color;
    font-weight: $theme-text-font-weight;
    background: #eee;

    p {
        color: $theme-text-color;
        font-size: 1.3rem;
        font-weight: 400;
    }
}

main {
    background: #fff;
    overflow: hidden;
}

// Loading
// ---------------------------------------------------------------------------------------------------------------------

body {
    height: 100vh;
    overflow: hidden;

    &#index .header,
    #main {
        opacity: 0;
    }

    &.-loaded {
        height: auto;
        overflow: visible;

        &#index .header,
        #main {
            opacity: 1;
        }
    }
}

// Container Width
// ---------------------------------------------------------------------------------------------------------------------

.container {
    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }

    &.-large {
        max-width: 1270px;
    }

    &.-xlarge {
        max-width: 1440px;
    }

    &.-container-products {
        @include media-breakpoint-up(xxl) {
            max-width: 1720px;
        }
    }
}

.container-fluid {
    &.full-width {
        padding: 0px;
        @include media-breakpoint-down(md) {
            padding: 0 15px;
            &--header {
                padding: 0px !important;
            }
        }
    }
}


// Make page content a container
body:not(#index) {
    #content.page-content {
        // @extend .container-fluid;
        max-width: 100% !important;
    }
}

.page-footer {
    text-align: center;
    font-size: 1.2rem;
}

// Wrapper
// ---------------------------------------------------------------------------------------------------------------------

/*
body:not(#index) #wrapper {
    @include media-breakpoint-down(sm) {
        margin-top: 35px;
    }

    @include media-breakpoint-down(lg) {
        margin-top: 15px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 15px;
    }
}

body#index {
    #wrapper {
        margin-top: 70px;
        @include media-breakpoint-down(lg) {
            margin-top: 0px;
        }
    }
} */

body#product {
    #wrapper {
        // margin-top: 20px;
        // margin-top: 100px;

        @include media-breakpoint-up(lg) {
            margin-top: -11px;
        }
    }
}

// body.advancedsearch-seo {
//     #wrapper {
//         margin-top: 130px !important;
//         @include media-breakpoint-down(md) {
//             margin-top: 120px !important;
//         }
//     }
//     .product-list-header {
//         .container-title {
//             margin-top: 320px;
//             @include media-breakpoint-down(sm) {
//                 margin-top: 0px;
//             }
//         }
//     }
//     #js-product-list {
//         margin-top: 40px !important;
//     }
// }

// body#pagenotfound {
//     #wrapper {
//         margin-top: 70px;
//         @include media-breakpoint-down(md) {
//             margin-top: 0px;
//         }
//     }
// }

// Text style
// ---------------------------------------------------------------------------------------------------------------------

.text-smaller {
    font-size: 0.75em;
}

// Outlined Text
// ---------------------------------------------------------------------------------------------------------------------

.text-outline {
    -webkit-text-stroke: 1px map_get($pt-colors, "black");
    color: map_get($pt-colors, "white");

    letter-spacing: 0.35rem;
    font-family: $theme-title-font;
    font-weight: bold;
    text-transform: uppercase;
}

// Links global
// ---------------------------------------------------------------------------------------------------------------------

$pt-link-hover-color: #f5633e;

#header,
body {
    a {
        @include link-colors(inherit, $pt-link-hover-color, inherit);
    }
}

#header {
    a {
        @include link-colors(inherit, inherit, inherit);

        &:hover {
            text-decoration: none;
            span {
                @extend .text-border-bottom;
            }
        }
    }
}

.custom-radio input[type="radio"]:checked + span {
    background: $pt-link-hover-color;
}

.page-my-account #content .links a:hover i {
    color: $pt-link-hover-color;
}

.form-control:focus,
.input-group.focus {
    border-color: $pt-link-hover-color;
    outline: 0.1875rem solid $pt-link-hover-color;
}

/// Slice Global Layout
// ---------------------------------------------------------------------------------------------------------------------

.slice-section {
    padding: 12px 0;

    &:first-child {
        padding-top: 0;
    }

    @include media-breakpoint-up(md) {
        &:first-child {
            padding: 48px 0;
        }

        padding: 48px 0;
    }

    @include media-breakpoint-up(lg) {
        &:first-child {
            padding: 68px 0;
        }

        padding: 68px 0;
    }
}

.slice-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 6rem;

    @include media-breakpoint-down(sm) {
        font-size: 3rem;
    }

    .pt-monogramme {
        display: inline-block;
    }

    &.-text {
        @extend .text-outline;
        font-size: 6rem;
        margin-bottom: 80px;
        @include transition(color 250ms $easeOutQuart);

        @include media-breakpoint-up(md) {
            font-size: 11rem;
            margin-bottom: 0;
        }
    }

    h2 {
        font-size: 1em;
        margin: 0;
        padding: 0;
        line-height: 1;
        width: 50%;
        margin: 0 auto;
    }
}

.slice-header-link:hover h2,
.slice-header-link:hover .slice-header.-text {
    color: #000;
}

.slice-footer {
    text-align: center;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }

    .big-button {
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}

// Position fix
// ---------------------------------------------------------------------------------------------------------------------

.slices.-homepage {
    .slice-section:first-child:not(.slice-carousel) {
        position: relative;
        top: 23px;
        // @include media-breakpoint-down(md) {
        //     top: 0px;
        //     padding: 48px 0 0;
        // }
        @include media-breakpoint-down(lg) {
            top: 43px;
        }
        @include media-breakpoint-down(md) {
            top: 35px;
        }
        @include media-breakpoint-down(sm) {
            top: 0px;
        }
    }

    .slice-banner_with_caption + .clearfix + .slice-prestashop_widget {
        .slice-header {
            margin-top: 30px;

            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }
}

// Entry (basic layout for text content)
// ---------------------------------------------------------------------------------------------------------------------

.entry {
    h1,
    h2,
    h3,
    h4 {
        text-transform: uppercase;
        font-family: $theme-title-font;
        font-weight: $theme-title-font-weight;
        line-height: 1.1;
        letter-spacing: 0.05rem;
    }

    h2 {
        font-size: 3rem;
        margin: 2.5rem 0;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto 1rem auto;
    }

    h3 {
        font-size: 1.6rem;
        margin: 1.5rem auto;
    }

    p {
        font-family: $theme-text-font;
        line-height: 1.56;

        strong {
            font-weight: 600;
        }

        a {
            @include link-colors(
                #d63e3a,
                darken(#d63e3a, 12%),
                #d63e3a,
                #d63e3a
            );

            text-decoration: underline;
        }
    }

    ul {
        list-style-type: disc;
        margin: 1em 0 1em 0;
        padding: 0 0 0 40px;
    }

    ol,
    ul {
        li {
            font-size: 1.3rem;

            & + li {
                margin-top: 4px;
            }
        }
    }
}

// Buttons
// ---------------------------------------------------------------------------------------------------------------------

.big-button {
    @include appearance(none);
    border: 0;
    display: inline-block;
    font-size: 1.3rem;
    padding: 30px 40px;
    position: relative;
    z-index: 3;
    cursor: pointer;

    @include media-breakpoint-up(md) {
        padding: 35px 40px;
    }

    @include link-colors(
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black")
    );

    svg,
    span {
        z-index: 3;
        user-select: none;
        position: relative;
        vertical-align: middle;
    }

    span {
        border-bottom: solid 1px map-get($pt-colors, "black");
    }

    svg {
        margin-left: 70px;
    }

    &::after,
    &::before {
        content: "";
        display: block;
        position: absolute;
        background: map-get($pt-colors, "white");
        border: solid 1px map-get($pt-colors, "black");
        width: 100%;
        height: 100%;
    }

    &::after {
        top: 0px;
        left: 0px;
        z-index: 2;
    }

    &::before {
        pointer-events: none;
        top: 10px;
        left: 10px;
        z-index: 1;
        @include transition(transform 150ms $easeInOutExpo);
        @include transform2d(translateX(0px) translateY(0px));
    }

    &:hover {
        &::before {
            @include transform2d(translateX(-10px) translateY(-10px));
        }
    }
}

.colored-button {
    display: inline-block;
    font-size: 1.3rem;
    padding: 20px 25px;
    position: relative;
    z-index: 3;

    @include media-breakpoint-up(md) {
        padding: 35px 40px;
    }

    @include link-colors(
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black"),
        map-get($pt-colors, "black")
    );

    svg,
    span {
        z-index: 3;
        user-select: none;
        position: relative;
        vertical-align: middle;
    }

    span {
        border-bottom: solid 1px map-get($pt-colors, "black");
    }

    svg {
        margin-left: 70px;
        @include media-breakpoint-down(sm) {
            margin-left: 30px;
        }
    }
}

.btn-primary,
.btn-secondary,
.btn-tertiary {
    box-shadow: none;
    text-transform: none;
    font-weight: 400;
    padding: 30px 40px;
    color: map-get($pt-colors, "black") !important;
    background-color: transparent;
    border: solid 1px map-get($pt-colors, "black");
    font-size: 1.2rem;
    cursor: pointer;

    @include transition(all 250ms $easeInOutExpo);

    span,
    svg {
        vertical-align: middle;
    }

    span {
        @extend .text-border-bottom;
    }

    svg {
        font-size: 1.3rem;
        margin-left: 10px;
    }

    &:hover,
    &:focus {
        color: map-get($pt-colors, "black");
        background-color: transparent;
        border-color: map-get($pt-colors, "black");
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &:active {
        outline: 0;
        color: map-get($pt-colors, "white") !important;
        background-color: map-get($pt-colors, "black") !important;
        border-color: map-get($pt-colors, "black") !important;
        box-shadow: none;

        span {
            border-bottom: solid 1px map-get($pt-colors, "white") !important;
        }
    }

    &.-naked {
        border: solid 1px transparent;

        &:hover {
            box-shadow: none;
        }
    }
}

.btn-primary-black {
    background: #000;
    color: #fff !important;

    span {
        @extend .text-border-bottom;
    }

    &:hover {
        color: #000 !important;
    }
}

// Pagination
// ---------------------------------------------------------------------------------------------------------------------
.pagination-container {
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 7px;
        padding-right: 25px;
    }
}

.custom-pagination {
    display: flex;
    align-items: inherit;
    justify-content: flex-end;
    height: 100%;
    margin: 10px 0 0 0;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        justify-content: center;
        flex-direction: column;
    }

    @include media-breakpoint-up(sm) {
        margin: -17px 0 0 0;
    }

    @include media-breakpoint-down(lg) {
        margin: -17px 0 0 0;
        flex-direction: column;
    }

    ul {
        margin-right: 10px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &:after {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            margin: 0px 0px 20px 0px;
        }

        @include media-breakpoint-down(md) {
            margin: 70px 0px 20px;
        }

        @include reset(ul);

        li {
            display: inline-block;

            &.current a {
                border: solid 1px map-get($pt-colors, "black");
            }

            &:not(.current) .disabled {
                display: none;
            }

            &.see-all-btn {
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    position: relative;
                    //left: -20px;
                    margin: 20px 0 0;
                }
            }
        }

        // li:last-child {
        //   @include media-breakpoint-down(sm) {
        //     width: 100%;
        //   }
        // }
    }

    a {
        display: block;
        min-width: 39px;
        height: 77px;
        border: solid 1px map-get($pt-colors, "white");
        line-height: 77px;
        font-size: 1.2rem;
        text-align: center;
        margin: 0 2px;

        @include media-breakpoint-down(sm) {
            // min-width: 44px;
            height: 44px;
            line-height: 44px;
            margin: 0px;
        }
        @include media-breakpoint-up(md) {
            min-width: 77px;
        }

        @include link-colors(
            map-get($pt-colors, "black"),
            map-get($pt-colors, "black"),
            map-get($pt-colors, "black"),
            map-get($pt-colors, "black"),
            map-get($pt-colors, "black")
        );

        &:hover {
            border: solid 1px map-get($pt-colors, "black");
        }

        &[rel="prev"],
        &[rel="next"] {
            min-width: auto;
            // padding: 0 27px;
            border: solid 1px map-get($pt-colors, "black");
            background: #fff;
            z-index: 1;
            position: relative;
            transition: 0.3s ease;

            @include media-breakpoint-down(sm) {
                padding: 20px;
                height: 44px;
                line-height: 44px;
            }

            &:hover {
                transform: translate(10px, 10px);
            }

            span.arrow {
                display: block;
                background: url("../img/arrow-pag.svg") no-repeat;
                width: 25px;
                height: 15px;
                background-size: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.prev {
                    transform: rotate(180deg) translate(50%, 50%);
                }
            }
        }
    }
    span.shadow {
        border: solid 1px map-get($pt-colors, "black");
        background-color: #ecb7b7;
        position: absolute;
        z-index: 0;
        right: -10px;
        bottom: -10px;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(sm) {
            right: -5px;
            bottom: -5px;
        }
    }

    .container-link {
        position: relative;
        // top: 27px;
        height: 77px;
        width: 77px;

        a {
            margin: 0px;
        }

        @include media-breakpoint-down(sm) {
            height: 100%;
        }

        @include media-breakpoint-down(sm) {
            // top: 17px;
        }
    }
}

.see-all-container {
    top: initial;
    margin: 0px 0px 0px 35px;
    // top: 12px;
    width: auto !important;

    @include media-breakpoint-down(md) {
        margin: 25px 0px 0 22px;
        width: 70%;
        left: 50%;
        transform: translateX(-50%);
    }

    @include media-breakpoint-down(lg) {
        margin: 25px 0px 0 0px;
    }

    @include media-breakpoint-down(md) {
        margin: 0px;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
        height: 44px !important;
    }

    a {
        min-width: auto;
        padding: 0 27px;
        border: solid 1px map-get($pt-colors, "black");
        background: #fff;
        z-index: 1;
        position: relative;
        transition: 0.3s ease;

        &:hover {
            transform: translate(10px, 10px);
        }
        span {
            white-space: nowrap;
        }
    }
}
