.topheader {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #000;
    border-left: 0;
    border-right: 0;
    p {
        font-family: "Futura LT", sans-serif;
        font-size: 10.5px;
        text-align: center;
        margin: 0;
        padding: 2px 10px;
    }
    a {
        text-decoration: underline;
    }
}
