.infobox {
  width: 100%;
  font-size: 1.25rem;
  line-height: 1;
  position: relative;
  z-index: 2;

  @include transition(all 180ms);

  @include media-breakpoint-up(md) {

    &.size-regular {
      width: 380px;
      height: 82px;
    }

    &.size-small {
      width: 82px;
      height: 82px;
    }
  }

  &__entry {
    display: flex;
    width: 100%;

    & > div:first-child {
      flex:1;
    }
  }


  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    background: map-get($pt-colors, 'white');
    border: solid 1px map-get($pt-colors, 'black');
    color: map-get($pt-colors, 'black');

    @include media-breakpoint-up(md) {
      padding: 0 30px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    background: map-get($pt-colors, 'white');
    border: solid 1px map-get($pt-colors, 'black');
    width: 100%;
    height: 100%;
    top: 10px;
    left: 10px;
    z-index: -1;
    @include transition(transform 150ms $easeInOutExpo);
    @include transform2d(translateX(0px) translateY(0px));
  }

  &.-no-relief {
    &::before {
      @include transform2d(translateX(-10px) translateY(-10px));
    }
  }

  &.-no-before {
    &::before {
      display: none;
    }
  }

  a {
    @include link-colors(map-get($pt-colors, 'black'),map-get($pt-colors, 'black'),map-get($pt-colors, 'black'));
  }

}