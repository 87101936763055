.container-breadcrumb{

  .breadcrumb {

    color: map_get($pt-colors,'black');
    font-size: 1.2rem;
    margin-bottom: 0;
    background: map_get($pt-colors,'white');
    padding: .75rem 0;

    ol {
        padding-left: 0;
        list-style: none;
    

        &[data-depth="1"] {
          display: block;
        }

        li {
          display: inline;

          &::after {
            content: '>';
            color: map_get($pt-colors,'black');
          }

          &:last-child {
            color: map_get($pt-colors,'black');

            &::after{
              content: '';
            }
          }

          a {
            display: inline-block;

            @include link-colors(
                            map_get($pt-colors,'black'),
                            map_get($pt-colors,'black'),
                            map_get($pt-colors,'black'),
                            map_get($pt-colors,'black'),
                            map_get($pt-colors,'black')
            );
          }
        }
    }

  }

  #cart .breadcrumb {
    display: none;
  }

  body.page-cart .card {
   border: 0;
  }

  .cart-grid {
    margin-bottom: 0;
  }

}

body#index {
    .container-breadcrumb {
      display: none !important;
    }
}

body:not(#product) {
  .container-breadcrumb {
    margin-top: 0;
  }
}
