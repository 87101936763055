.product-metas {

    position: relative;
    margin: 20px;
    font-weight: 300;

    @include media-breakpoint-up(md) {
        margin: 40px;
    }

    @include media-breakpoint-up(xl) {
        margin: 60px 35px 35px;
    }

    @include media-breakpoint-up(sm) {
        .product-prices {
            position: absolute;
            top: -17px;
            right: 0;
        }
    }

    &__header {
        margin-bottom: 0px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 25px;
        }
    }

    &__subname {

        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
            font-size: 1.3rem;
            margin-bottom: 25px;
        }
        a {
            @include link-colors(#000,#000,#000);

            &:hover {
                text-decoration: none;
                @extend .text-border-bottom;
            }
        }
    }

    &__name {
        margin: 0;
        padding: 0;
        line-height: 1.4;
        font-size: 2.1rem;
        text-transform: uppercase;
        font-weight: bold;

        span {
            // @extend .text-border-bottom;
        }
    }
}

.product-actions {
    .control-label {
        font-size: 1.4rem;
        margin-bottom: 7px;
    }
}

.product-prices {

    text-align: right;
    font-size: 2.1rem;
    margin-top: 0;
    line-height: normal;

    @include media-breakpoint-up(md) {
        text-align: center;
        background: url('../img/price-shape.svg');
        background-size: 100% 100%;
        width: 121px;
        height: 121px;
        margin: 0;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 300;
        @include transform2d(rotate(8deg));
    }

    div {
        margin: 0;
    }

    .product-price,
    .has-discount.product-price,
    .has-discount p,
    .product-discount {
        color: map_get($pt-colors, 'black');
        font-weight: $theme-text-font-weight;
    }

    .product-discount {
        .regular-price {
            font-weight: $theme-text-font-weight;
            margin-right: 0;
            font-size: 1.15rem;
            color: red;
        }
        margin-bottom: 6px;
    }
}

.product-quantity {

    .add,
    .qty {
        height: 60px;
        float: none;
        display: block;
        margin: 0;
    }

    .qty {
        flex-basis: 110px;
        margin-right: 10px;
    }

    .add {
        flex: 1;
    }

    .bootstrap-touchspin {
        height: 100%;
        box-shadow: none;
        border: solid 1px map_get($pt-colors, 'black');
        background: #fff;
        width: 110px;

        #quantity_wanted {
            height: 100%;
            border: none;
            width: calc(100% - 30px);
            font-size: 1.6rem;
            font-weight: 400;
            text-align: center;
        }

        .input-group-btn-vertical {
            width: 30px;
            height: 100%;
            border-left: solid 1px map_get($pt-colors, 'black');
            display: flex;
            flex-direction: column;

            & > .btn {
                margin-left: 0;
                height: 50%;
                flex: 1;
                cursor: pointer;

                &:hover {
                    background: map_get($pt-colors, 'black');
                    color: #fff;
                }

                & > * {
                    position: static;
                }

                i {
                    font-size: 1.4rem;
                }
            }

            .bootstrap-touchspin-up {
                border-bottom: solid;
            }
        }

        .btn-touchspin {
            border: 0;
        }
    }

    .add-to-cart {
        height: 100%;
        width: 100%;
        color: map_get($pt-colors, 'black');
        background: map_get($pt-colors, 'turquoise');
        border: solid 1px map_get($pt-colors, 'black');
        padding: 0;
        border-radius: 0;
        font-size: 1.5rem;
        font-weight: 400;
        cursor: pointer;
        position: relative;


        @include appearance(none);

        span {
            @extend .text-border-bottom;
            padding-bottom: 2px;
            border-bottom: none;
        }

        &::after {
            display: block;
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: #303030;
            z-index: 1;
            top: 0;
            left: 0;
            @include transform(scaleY(0));
            @include transition(all 200ms $easeOutQuart);
        }

        & > * {
            position: relative;
            vertical-align: middle;
            z-index: 2;
        }

        &:disabled {
            opacity: 0.2
        }

        &:hover::after {
            @include transform(scaleY(1));
        }

        &:hover {
            color: white;
        }

    }

}

.product-add-to-cart {

    &.-unavailable {
        padding-top: 20px;
        @include media-breakpoint-up(sm) {
            padding-top: 40px;
        }

        .add-to-cart{
            background:#ccc;
            color:#000;
            opacity:1;
            cursor: not-allowed;

            &:after {
                display: none;
            }
        }
    }

    &.-unavailable.-customization-required {
        padding: 0;
    }

    margin-bottom: 10px;

    // @include media-breakpoint-up(sm) {
    //     margin-bottom: 10px;
    // }

    #product-availability,
    .product-minimal-quantity {
        &:empty {
            display: none;
        }
    }

    #product-availability {
        margin-top: 10px;
        border: solid 1px map_get($pt-colors, 'black');
        padding: 10px;
        width: 100%;
        font-weight: 400;
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
}

.product-customization-required {
    padding: .75rem 1.25rem;
    border: solid 1px #000;
    font-size: 1.2rem;
    margin-bottom: 20px;

    &__title {
        font-size: 1.5rem;
        @extend .text-border-bottom;
        display: inline-block;
        margin-bottom: 15px;
    }

    p {
        font-weight: 300;
    }
}

.product-tabs {
    margin-top: 25px;

    ul.tab-nav {
        @include inline-block-list();
        margin-bottom: 10px;

        li {
            cursor: pointer;
            font-size: 1.4rem;
            margin-right: 25px;

            &.-current {
                span {
                    @extend .text-border-bottom
                }
            }
        }
    }

    &__content {
        display: none;

        $p-line-height: 1.5;
        $p-font-weight: 300;
        $p-font-size: 1.2rem;

        font-weight: $p-font-weight;
        font-size: $p-font-size;
        color: #000!important;
        line-height: $p-line-height;

        span {
            color: #000!important;
        }

        ul,
        p {
            font-weight: $p-font-weight;
            font-size: $p-font-size;
            color: #000!important;
            line-height: $p-line-height;

            span {
                color: #000!important;
            }
        }

        &.-current {
            display: inherit;
        }
    }

}

.product-wrapper {
    position: relative;
}

.product-features-list {
    li + li {
        border-top: solid 1px #eee;
        padding-top: 3px;
        margin-top: 3px;

    }
}

.product-metas-container {

    $product-metas-container-min-height: 600px;

    position: relative;
    width: 100%;

    pointer-events: all;

    @include media-breakpoint-up(md) {
        width: 80%;
        margin: 0 10% 0 21%;
        max-width: 480px;
    }

    @include media-breakpoint-up(xl) {

        margin: 0 15px 0 auto;
        min-height: $product-metas-container-min-height;
    }

    @include media-breakpoint-up(xxl) {
        max-width: 540px;
    }

    @include media-breakpoint-between(sm,md) {
        margin: 0 auto;
    }

    .product-metas-entry {
        width: 100%;
        min-height: $product-metas-container-min-height;
        height: 100%;
        background: map_get($pt-colors, 'white');
        border: solid 1px map_get($pt-colors, 'black') ;
        position: relative;
        z-index: 2;

        @include media-breakpoint-down(sm) {
            min-height: initial;
          }
    }

    /*&::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: map_get($pt-colors, 'white');
        border: solid 1px map_get($pt-colors, 'black');
        position: absolute;
        right: -10px;
        top: 10px;
    }*/

    &.-is-customizable {
        &::after {
            display: none;
        }
    }

    &.-flip {
        .product-customization {
            animation: flipPaneProductCustomization 800ms cubic-bezier(0.45, 0.59, 0, 1.01);
            animation-fill-mode: forwards;
        }

        .product-metas-entry {
            animation: flipPaneProductMetas 800ms cubic-bezier(0.45, 0.59, 0, 1.01);
            animation-fill-mode: forwards;
        }
    }
}

.product-sticky {
    position: relative;
    top: 0;
    margin-top: 15px;

    @include media-breakpoint-up(md) {
        top: -70px;
        margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 30px;
        width: 100%;
        pointer-events: none;
    }
}

$cell-padding: 15px;

.product-image-slider {
    padding-top: 0px;

    .flickity-button,
    .flickity-page-dots {
        display: none;
    }
}

.product-image-cell {

    @include media-breakpoint-down(md) {
        width: 100%;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        .product-image-cell-wrapper {
            @include transform2d(translateY($cell-padding*-1));
            @include transition(transform 350ms $easeInOutExpo);
            padding: $cell-padding;

            img {
                width: 590px;
                height: auto;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .product-image-cell-wrapper {
            img {
                width: 700px;
                height: auto;
            }
        }
    }

    // &.is-selected {
    //     @include media-breakpoint-up(xl) {
    //         .product-image-cell-wrapper {
    //             @include transform2d(translateY(22px));
    //         }
    //     }
    // }

    /*
        &.product-image-cell:not(.is-selected) {
            @include media-breakpoint-up(md) {
                .product-image-cell-wrapper {
                    padding-left: 0;
                }
            }
        }

        &.is-selected {
            @include media-breakpoint-up(md) {
                .product-image-cell-wrapper {
                    padding: $cell-padding 0;
                    @include transform2d(translateY(-0px));
                }
            }
        }

        &.is-selected + .product-image-cell,
        &:first-child:not(.is-selected) {
            @include media-breakpoint-up(md) {
                .product-image-cell-wrapper {
                    padding-left: $cell-padding;
                }
            }
        }

        &:last-child:not(.is-selected) {
            @include media-breakpoint-up(md) {
                .product-image-cell-wrapper {
                    padding-right: 0;
                }
            }
        }

        &:first-child.is-selected {
            @include media-breakpoint-up(md) {
                .product-image-cell-wrapper {
                    padding-left: 0;
                }
            }
        }

        &:first-child.is-selected ~ .product-image-cell:last-child {
            @include media-breakpoint-up(md) {
                img {
                    padding-right: $cell-padding;
                }
            }
        }
    */
}

.product-variants {
    & >.product-variants-item {
        select {
            box-shadow: none;
        }
    }
}

.product-customize-button {
    font-weight: 300;
    font-size: 1.5rem;

    &::before {
        content: '';
        background: url('../img/pen-custom.png');
        width: 25px;
        height: 25px;
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
        margin-right: 13px;
    }

    &:focus {
        text-decoration: none;
    }

    @include link-colors(
                    map_get($pt-colors, 'black'),
                    map_get($pt-colors, 'black'),
                    map_get($pt-colors, 'black'),
                    map_get($pt-colors, 'black'),
                    map_get($pt-colors, 'black')
    );

    span {
        vertical-align: middle;
    }
}

.product-customize-value {
    margin-right: 10px;
}

.product-customize-button + .product-tabs {
    margin-top: 25px;

    @include media-breakpoint-up(sm) {
        margin-top: 45px;
    }
}

.form-control-select {
    background-color: map_get($pt-colors, 'white');
}

.form-control {
    color: map_get($pt-colors, 'black');
    border-color: map_get($pt-colors, 'black');
}

.color {
    box-shadow: none;
    border-color: map_get($pt-colors, 'black');
    width: 25px;
    height: 25px;
}

.custom-arrow {
    pointer-events: all;
    position: absolute;
    width: 80px;
    height: 80px;
    @include appearance(none);
    border: solid 1px map_get($pt-colors, #000);
    background: #fff;
    @include transition(all 130ms $easeInSine);
    @include transform2d(translateX(0px));
    cursor: pointer;

    &:focus {
        outline: 0;
    }

    &.disabled {
        display: none !important;
    }
}

.product-image-slider-wrapper {

    .custom-arrow {
        z-index: 3;
        width: 52px !important;
        height: 52px !important;
        svg {
            transform: scale(1) !important;
        }
        @include transform2d(translateX(0px));

        top: 150px;

        @include media-breakpoint-up(md) {
            top: 300px;
            display: block;


        }

        @include media-breakpoint-between(sm,md) {
            top: 250px;
        }

        &.previous {
            left: calc(50% - 740px) !important;

            @include media-breakpoint-down(lg) {
                left: 5px !important;
            }

            @include media-breakpoint-between(sm,md) {
                left: calc(25% - 100px) !important;
            }

            @include media-breakpoint-up(xl) {
                left: calc(50% - 680px) !important;
            }

            @include media-breakpoint-up(xxl) {
                left: calc(50% - 740px) !important;
            }

            &:hover {
                @include media-breakpoint-up(sm) {
                    @include transform2d(translateX(10px));
                }
            }
        }

        &.next {
            right: 5px;

            @include media-breakpoint-between(sm,md) {
                right: calc(25% - 100px);
            }

            @include media-breakpoint-up(lg) {
                right: calc(50% - 117px);
            }

            @include media-breakpoint-up(xl) {
                right: calc(50% - 57px);
            }

            @include media-breakpoint-up(xxl) {
                right: calc(50% + 3px);
            }

            &:hover {
                @include media-breakpoint-up(sm) {
                    @include transform2d(translateX(-10px));
                }
            }
        }

        svg {
            vertical-align: middle;
            transform: scale(1.5);
        }
    }
}

.product-section__wrapper {

    .custom-arrow {

        //padding: 20px 0;
        padding: 4px 0 0;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transform2d(translateX(0px));

        display: none;

        @include media-breakpoint-down(lg) {
            width: 52px !important;
            height: 52px !important;
            svg {
                transform: scale(1) !important;
            }
        }

        @include media-breakpoint-up(md) {
            top: 33%;
            display: block;

            @include media-breakpoint-between(sm,md) {
                top: 27%;
            }
        }

        &.previous {
            left: 0;

            @include media-breakpoint-up(xl) {
                left: 130px;
            }
            &:hover {
                @include transform2d(translateX(10px));
            }
        }

        &.next {
            right: 0;

            @include media-breakpoint-up(xl) {
                right: 130px;
            }

            &:hover {
                @include transform2d(translateX(-10px));
            }
        }

        svg {
            transform: scale(1.5);
        }
    }
}



.product-image-slider {
    position: relative;

    @include media-breakpoint-up(lg) {
        right: 505px;
        width: calc(100% + 505px);
        margin-bottom: 80px;

        .flickity-viewport {
            overflow: visible;
        }
    }

    @include media-breakpoint-up(xl) {
        right: 615px;
        width: calc(100% + 615px);
        margin-bottom: 80px;

        .flickity-viewport {
            overflow: visible;
        }
    }

    @include media-breakpoint-up(xxl) {
        right: 740px;
        width: calc(100% + 740px);
        margin-bottom: 80px;

        .flickity-viewport {
            overflow: visible;
        }
    }
}

.product-accessories {
    margin-bottom: 20px;
    position: relative;
    margin-top: 200px;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        // padding-right: 0px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 100px;
    }
}

.product-section {
    &__header {
        text-transform: uppercase;
        font-size: 4rem;
        font-family: $theme-title-font;
        font-weight: $theme-title-font-weight;
        line-height: 1.1;
        text-align: center;
        position: absolute;
        top: -70px;
        -webkit-text-stroke: 1px black;
        color: #fff;
        // transform: rotate(-8deg);
        -webkit-transform: skew(0deg,-9deg);
        -moz-transform: skew(0deg,-9deg);
        -ms-transform: skew(0deg,-9deg);
        -o-transform: skew(0deg,-9deg);
        transform: skew(0deg,-9deg);
        z-index: 2;
        letter-spacing: 4px;
        left: 32px;

        @include media-breakpoint-up(xl) {
            font-size: 5.4rem;
        }

        @include media-breakpoint-between(sm,lg) {
            top: -50px;
        }

        @include media-breakpoint-down(md) {
            font-size: 3rem;
            letter-spacing: 2px;
            top: -35px;
        }

        // &.-with-logo::before {
        //     content: '';
        //     background: url('../img/monogramme.svg');
        //     width: 65.5px;
        //     height: 30px;
        //     display: block;
        //     margin: 0 auto 20px auto;
        //     background-size: 100% 100%;
        // }

        span {
            border-bottom: none;
        }
    }

    &__wrapper {
        position: relative;
    }
}

.products-slider {

    position: relative;
    left: 1px;

    .carousel-cell {
        width: 75%;
        margin: 0 10px;

        @include media-breakpoint-up(md) {
            width: 28%;
            margin: 0 10px;
        }

        // @include media-breakpoint-between(md, lg) {
        //     width: 31%;
        // }

        @include media-breakpoint-down(sm) {
            width: 80%;
        }
    }

    .product-miniature {

        width: 100%;
        .product-thumbnail {
            height: auto;
        }
    }
}

// Product configurable
// ---------------------------------------------------------------------------------------------------------------------

.product-configurable {
    margin-bottom: 20px;

    &__title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.25rem;
        letter-spacing: 0.02rem;
    }

    &__content {
        p {
            font-weight: 300;
        }
    }

    a {
        @include appearance(none);
        color: black;
        border: solid 1px black;
        padding: 0;
        border-radius: 0;
        font-size: 1.5rem;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: #000;

            span {
                @extend .text-border-bottom;
            }
        }



        .product-configurable-notice__icon {
            width: 20px;
            height: 18px;
            margin-right: 5px;
        }
    }
}

.product-configurable-info {

    font-size: 12px;

    div:first-child {

    }
}

.product-configurable-reference {
    margin-top: 4px;
}

.off-grid {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.dp_container {

    left: 0;
    bottom: 0;
    background: #ccc;
    padding: 15px;
    border: solid 1px red;

    display: block!important;
    position: absolute;

    width: 1px;
    height: 1px;
    visibility: hidden;
    z-index: 1;
}


// Product informations
// ---------------------------------------------------------------------------------------------------------------------
.product-information {
    ul {
        padding-left: 20px;

        li {
            color: map_get($pt-colors, 'turquoise');
            font-size: 12px;
            margin: 5px 0;
            font-weight: 400;
            list-style-image: url('../img/tick.png');
        }
    }
}



// Product More Content blocks
// ---------------------------------------------------------------------------------------------------------------------

.product-more-content {
    margin-top: -20px;
    margin-bottom: 40px;

    .slice-section {
        padding: 25px 0;
    }

    &:empty {
        display: none;
    }
}



// Animations
// ---------------------------------------------------------------------------------------------------------------------

@keyframes flipPaneProductMetas {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    50% {
        transform: translateX(-15px) translateY(-15px);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes flipPaneProductCustomization {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    50% {
        transform: translateX(15px) translateY(15px);
        z-index: 3;
    }
    100% {
        transform: translateX(0px) translateY(0px);
        z-index: 3;
    }
}

// Customized product add to cart
// ---------------------------------------------------------------------------------------------------------------------

.loader-addtocart {
    font-family: "Futura LT", Futura, sans-serif;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    top: 0;
    left: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2em;
}
