.image-text-block {

  display: flex;
  flex-direction: column;
  height: 430px;
  justify-content: space-around;


  &.-v1,
  &.-v2 {
    flex-direction: column-reverse;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    img {

    }
  }

  &.-v1 {
    img {
      @include media-breakpoint-up(lg) {
        @include transform(rotate(-5deg));
      }
    }
  }

  &.-v2 {
    img {
      @include media-breakpoint-up(lg) {
        @include transform(rotate(5deg));
      }
    }
  }

  img {

    margin: 0 auto;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: map_get($pt-colors, 'white');
    margin: 0;
  }

  &__title {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 25px;
    }
  }

  &__text {
    text-align: justify;
    text-align-last: center;

    p {
      color: inherit;
      font-size: 1.3rem;
      line-height: 1.55;
      text-align: justify;
      text-align-last: center;
    }
  }
}

.image-text-block.style_1 {

  @include media-breakpoint-down(sm){
    margin-bottom: 80px;
  }

  &.-v1,
  &.-v2{
    flex-direction: column;

    @include media-breakpoint-down(sm){
      flex-direction: column-reverse;
    }
  }

  &:not(.-v1):not(.-v2){
    flex-direction: column-reverse;

    @include media-breakpoint-down(md){
      margin-bottom: 65px;
    }
  }

  &:not(.-v1):not(.-v2){
    .image-text-block__wrapper {
      top: 15px;
    }
  }

  .image-text-block__wrapper {
    margin: 0 auto;
    padding: 40px 30px;
    width: 85%;
    min-height: 60%;
  }
}


// Style 2
// ---------------------------------------------------------------------------------------------------------------------
.container-style.style_2 {

  h2 {
    color: white;
    font-size: 6rem;
    letter-spacing: 0.35rem;
    font-family: "Futura LT", Futura, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    margin: 0 0 70px 0;
    -webkit-text-stroke: 1px #000;
    -webkit-user-select: none;
    -webkit-transform: skew(0deg, -10deg);
    -moz-transform: skew(0deg, -10deg);
    -ms-transform: skew(0deg, -10deg);
    -o-transform: skew(0deg, -10deg);
    transform: skew(0deg, -10deg);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    z-index: 10;
    left: 30px;

    @include media-breakpoint-down(sm) {
      margin: 0;
      font-size: 3rem;
    }

  }

  a {
    height: calc(50% - 30px);
  }

  .image-text-block {

    img {
      @include media-breakpoint-down(lg) {
        bottom: 0;
      }
    }

    &.-v1,
    &.-v2{
      img {
        -webkit-transform: inherit;
        -moz-transform: inherit;
        -ms-transform: inherit;
        -o-transform: inherit;
        transform: inherit;
        padding: 0;
      }
    }

    &__wrapper {
      border: 1px solid #000;
      margin: 0;
      height: calc(50% - 30px);

      > div {
        padding: 30px 40px;

        @include media-breakpoint-down(lg) {
          padding: 30px 15px;
        }
      }
    }

    &__title,
    &__text {
      color: #000;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 1.3rem !important;
        line-height: 18px;
      }
    }

    &__title {
      margin-bottom: 15px;
      font-weight: 600;
    }
  }
}



// Image avec du texte comportant également des images
// ---------------------------------------------------------------------------------------------------------------------
.image_with_rich_text {
  .text-block {
    .__text {
      padding: 0 35px;

      @include media-breakpoint-down(md) {
        padding: 0;
      }

      h2 {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
      }

      p{
        letter-spacing: 1px;
        line-height: 18px;
        font-size: 1.3rem !important;

        strong {
          font-weight: 600;
        }
      }
    }

    .__img {
      margin: 60px 0;

      > div {
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
          padding: 0;
        }
      }
    }
  }
}



// Special layout
// ---------------------------------------------------------------------------------------------------------------------

.in-module-w3prismic-single {
  @include media-breakpoint-up(sm) {

    .image-text-block {
      padding-top: 10px;

      img {
        bottom: 10px;
      }

      &.-v2,
      &.-v1 {
        padding-top: 220px;
        img {
          padding-top: 16px;
        }
      }
    }
  }
}