.container-products-match {

  @include media-breakpoint-down(md) {
      margin-top: 100px;
  }

  .products-match {

    margin-top: -50px;
    // padding: 0px 0px 110px 15px;
    padding: 0px 15px 50px;
    position:relative;

    @include media-breakpoint-up(md) {
      margin-top: 125px;
      padding: 0 15px 50px;
    }

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

    .carousel-cell {
      > a {
        position:relative;
        width: 100%;
        display: block;

        &:after{
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        .content-square {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

    }

    .products {
      width: 120%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      padding: 0 0 10px 0;
      left: -26px;

      @include media-breakpoint-up(md) {
        max-width: 1140px;
        width: 90%;
        left: 0;
      }

      img {
        max-width: 100%;
        height: auto;
      }

    }

    h2 {
      text-transform: uppercase;
      text-align: center;
      padding: 30px 0;
      border: 2px solid black;
      margin: 0;
      margin-top: -1px;
      position: relative;

      @include media-breakpoint-between(md, lg) {
        font-size: 1.8rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }
    }

    img{
      width: 100%;
      object-fit: cover;
      height: 100%;

      // @include media-breakpoint-down(md) {
      //   height: 100%
      // }
    }

    .products-wrapper {
      @include transform(rotate(-10.9deg))
    }

    .flickity-enabled:focus { outline: none; }

    .flickity-cell {
      width: 50%;

      @include media-breakpoint-up(md) {
        width: 20%;
      }

      & > * {
        padding: 15px;
      }

      &__img {
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          border: solid 1px map_get($pt-colors, 'black');
          background: map_get($pt-colors, 'white');
          width: 100%;
          height: 100%;
          top: 10px;
          left: 10px;
          z-index: 1;
          opacity: 1;
          @include transition(transform 150ms $easeInOutExpo);
          @include transform2d(translateX(0px) translateY(0px))
        }
      }

      img {
        position: relative;
        z-index: 2;
        border: solid 1px map_get($pt-colors, 'black');
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;

        &:hover {

          img {
            border: solid 1px map_get($pt-colors, 'black');
          }

          .flickity-cell__img::after {
            opacity: 0;
            @include transform2d(translateX(-10px) translateY(-10px));
          }
        }
      }

    }
  }

}
