// Global page components
// ---------------------------------------------------------------------------------------------------------------------

body {
  .page-header {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
      margin-top: 55px;
      margin-bottom: 50px!important;
    }
    h1 {
      text-align: center !important;
    }
  }
}

.page-header {

  text-align: center;
  display: inline-block;
  margin-top: 50px;
  width: 100%;
  max-width: 100%;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  h1 {
    color: map_get($pt-colors,'white');
    font-size: 3.6rem;
    letter-spacing: 0.35rem;
    font-family: $theme-title-font;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    -webkit-text-stroke: 1px #000;
    -webkit-user-select: none;
    position: relative;
    z-index: 10;
    margin: 0;
    width: 100%;
    text-align: left;
    padding-left: 15px;

    @include media-breakpoint-up(md) {
      font-size: 5.6rem;
    }

    @include media-breakpoint-down(md) {
      margin: 0 0 20px 0;
    }

    @include media-breakpoint-up(md) {
      transform: skew(0deg,-11deg);
      text-align: left;
      width: 490px;
    }
  }
}

#content.page-content {

  p {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  &.page-not-found {
    text-align: center;
    margin-bottom: 0!important;
  }
}

@media (max-width: 767.98px) {
  body#module-w3prismic-single, body#cms, body#contact {
    margin-top: 0px !important;
  }
}

@media (max-width: 767.98px) {
  body#contact .page-header h1, body#module-w3prismic-single .page-header h1 {
    margin: 20px 0 20px 0px !important;
  }
}

// Prismic Single Page
// ---------------------------------------------------------------------------------------------------------------------

body.page-single {
  .slice-section {
    padding: 45px 0;
  }

  .page-header + .slice-section {
    padding: 0 0 45px;
  }

  .page-content {
    margin-top: -15px;

    p {
      font-size: inherit;
    }
  }
}



