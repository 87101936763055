// Texts
// ---------------------------------------------------------------------------------------------------------------------

.blockcart-sidebar .h1 {
  font-family: $theme-title-font;
  font-weight: $theme-title-font-weight;
  font-size: 3rem;
  margin: 15px 0;
}

// Buttons
// ---------------------------------------------------------------------------------------------------------------------

.blockcart-sidebar {

  .cart-detailed-actions {

    margin-top: 20px;

    .btn.btn-primary {
      width: 100%;
      border-radius: 0px;
      background: #000;
      color: #fff!important;
      font-size: 14px;
      padding: 13px;


      svg {
        margin-left: 2px;
      }

      span {
        padding-bottom: 2px;
        border-bottom: solid 1px #000;
      }

      &:hover {
        span {
          border-bottom: solid 1px #fff;
        }
      }

      @include media-breakpoint-up(sm) {
        font-size: 15px;
        padding: 20px;
      }
    }

  }
}

.cart-continue-shopping {
  a {
    width: 100%;
    background: #fff;
    color: #000;
    border: 0;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;

    span {
      padding-bottom: 2px;
      border-bottom: solid 1px #fff;
    }

    &:hover {
      span {
        border-bottom-color: #000;
      }
    }


    @include media-breakpoint-up(sm) {
      font-size: 12px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.blockcart-sidebar .product-price, .blockcart-sidebar .product-line-info + .product-line-info {
  margin-top: 5px;
}

// Custom
// ---------------------------------------------------------------------------------------------------------------------

.dp_input_div {
  padding-left: 0;
  margin-top: 5px;
}

.dp_url {
  color: #000;
  text-transform: uppercase;
  font-size: 11px;
  border: solid 1px;
  padding:0 5px;
  display: inline-block;
}

.product-name-line a {
  @extend .text-border-bottom;

  &:hover {
    text-decoration: none!important;
  }
}

.dp-modify-item {
  border: solid 1px;
  padding: 1px 5px;
  display: inline-block;
  margin-top: 4px;
}

// Touchspin
// ---------------------------------------------------------------------------------------------------------------------

.blockcart-sidebar .bootstrap-touchspin,
body.page-cart .bootstrap-touchspin {
  border-radius: 0;
  border: solid 1px #000;
}

.blockcart-sidebar .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down,
body.page-cart .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-top: solid 1px #000;
}

.blockcart-sidebar .bootstrap-touchspin .input-group-btn-vertical,
body.page-cart .bootstrap-touchspin .input-group-btn-vertical {
  border-left: solid 1px #000;
}

.blockcart-sidebar .bootstrap-touchspin .input-group-btn-vertical > .btn,
body.page-cart .bootstrap-touchspin .input-group-btn-vertical > .btn {
  color: #000;
}

.blockcart-sidebar .bootstrap-touchspin .form-control,
body.page-cart .bootstrap-touchspin .form-control {
  font-weight: 400;
  color: #000;
}

//
// ---------------------------------------------------------------------------------------------------------------------

.blockcart-sidebar .cart-grid-right:after, body.page-cart .cart-grid-right:after {
  background: #fff;
}

body.page-cart {
  .cart-grid-body {
    margin-bottom: 0;
  }
}

.block-promo .promo-code {
  border-radius: 0;
}

.js-error-text:empty {
  display: none;
}