$customization-input-height:55px;

.product-customization {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 70px 20px;
  margin: 0;
  background: map_get($pt-colors, 'white');
  border: solid 1px map_get($pt-colors, 'black');
  z-index: 1;
  height: 100%;
  width: 100%;

  &__close {
    @include appearance(none);
    background: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    position: absolute;
    top: 30px;
    right: 30px;
    font-weight: 300;
    font-size: 1.3rem;
    cursor: pointer;

    &:focus,
    &:active {
      outline: 0;
    }

    & > * {
      vertical-align: middle;
    }

    .icon {
      margin-left: 15px;
    }
  }

  &__entry {
    strong:first-child {
      font-weight: 300;
      border-bottom: solid 1px;
      font-size: 2.4rem;
      line-height: 1.2;
      display: inline-block;
      margin-bottom: 30px;
    }
  }

  &__entry,
  &__helper {
    p {
      font-weight: 300;
      line-height: 1.55;
      font-size: 1.3rem;
    }
  }

  &__small {
    font-size: 1.1rem;
    color: map_get($pt-colors, 'black');
    font-weight: 300;
    padding-right: 80px;
  }

  .product-prices {
    position: absolute;
    right: 70px;
    width: 70px;
    height: 70px;
  }
}

.product-customization-form {
  display: flex;
  margin-bottom: 4px;
  margin-top: 30px;
}

.product-customization-item,
.product-customization-submit {
  height: $customization-input-height;
  border: solid 1px map_get($pt-colors, 'black');
  margin: 0;
  display: inline-block;
}

.product-customization-submit {
  padding: 0 30px;
}

.product-customization-helps {
  margin-top: 30px;
}

.product-customization-item {

  border-right: 0;
  margin: 0!important;
  flex: 1;

  input.product-message {
    @include appearance(none);
    border: 0;
    border-radius: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    font-size: 1.3rem;
    text-transform: uppercase;

    &:focus {
      border: 0;
      border-radius: 0;
      outline: 0;
    }

    &:invalid {
      color: red;
    }

    @include input-placeholder {
      font-style: italic;
      color: #858585;
    }
  }
}

.product-customization-submit {
  text-transform: uppercase;
}

.available-characters {
  font-weight: 600;
  font-size: 1.5rem;
}
