.reassurance {
  // @extend .container.-large;
  border-top: solid 1px map_get($pt-colors, 'black');
  border-bottom: solid 1px map_get($pt-colors, 'black');
  @include media-breakpoint-down(md) {
    border: solid 1px map_get($pt-colors, 'black');
  }

  padding: 0;

  .col-sm {
    &:first-child {
      //border-bottom: solid 1px map_get($pt-colors, 'black');

      @include media-breakpoint-up(sm) {
        border-bottom: 0;
        border-right: solid 1px map_get($pt-colors, 'black');
      }
    }

    &:last-child {
      border-top: solid 1px map_get($pt-colors, 'black');

      @include media-breakpoint-up(sm) {
        border-top: 0;
        border-left: solid 1px map_get($pt-colors, 'black');
      }
    }
  }
}

.reassurance-item {
  text-align: center;
  text-transform: uppercase;
  padding: 20px;

  @include media-breakpoint-up(sm) {
    padding: 45px 40px;
  }

  img {
    margin-bottom: 10px;
    width: 35px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-family: $theme-title-font;
    font-weight: $theme-title-font-weight;
    margin-bottom: 8px;
    font-size: 1.3rem;
    line-height: 1.4;

    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }

    span {
      border-bottom: 0;
    }
  }

  &__description {

    font-size: 1.3rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }

    // span {
    //   @extend .text-border-bottom;
    // }

  }
}
