.push-edito {
  background: map_get($pt-colors,'lightgrey');
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 220px;
  text-align: center;
  background-size: cover;
  background-position: center center;

  @include media-breakpoint-down(sm) {
    background-size: contain;
  }

  @include media-breakpoint-up(md) {
    height: 400px;
    margin-bottom: 55px;
  }

  @include media-breakpoint-up(lg) {
    height: 457.33px;
  }

  @include media-breakpoint-up(xxl) {
    height: 464px;
  }

  &__entry {

    padding: 30px;

    .btn {
      @extend .big-button;

      p {
        position: relative;
        z-index: 3;
        margin: 0;
      }

      span {
        white-space: pre-line;
      }
    }
  }

  &.style_1 {
    .push-edito__entry {
      text-align: left;

      h2 {
        font-weight: 100;
        text-transform: uppercase;
      }

      p {
        color: inherit;
        font-weight: 600;
      }

      .btn {
        padding: 25px 30px;

        &::before {
          background:#ecb7b7;
        }

        span {
          border:none;
          text-transform: uppercase;
        }
      }
    }
  }
}