.instafeed {

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  @include media-breakpoint-up(md) {
    height: 60vh;
    max-height: 600px;
    padding: 0;
  }

  &__medias {
    width: 350px;
    height: 438px;
  }

  &__item {
    width: 100%;
  }

  &__title {
    @extend .text-outline;
    line-height: 1;
    text-align: center;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    width: 100%;
    font-size: 4rem;
    z-index: 1;

    a:hover {
      color: #000;
    }

    @include media-breakpoint-up(md) {
      height: 110px;
      top: calc(50% - 55px);
      font-size: 11rem;
    }

    @include media-breakpoint-up(lg) {
      height: 140px;
      top: calc(50% - 60px);
      font-size: 14rem;
    }
  }
}