.cart-gifts {
  display: none;
  &.cart-gifts--visible {
    margin-bottom: 15px;
    margin-top: 25px;
    display: block;
  }
}

.cart-bar-container {
  text-align: center;
  &.cart-bar-container--current {
    display: block;
  }
}

.cart-bar-label {
  margin-bottom: 25px;
  font-size: 12px;
}

.cart-bar {
  height: 9px;
  border: solid 1px #000;
  width: 100%;
}

.cart-bar-progress {
  background: #ecb7b7;
  height: 100%;
  transition: all 250ms $easeInOutCirc;
}

.cart-gift-info {
  border-radius: 50%;
  background: #ecb7b7;
  width: 18px;
  height: 18px;
  color: #000;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: -1px;
  cursor: pointer;
}

.cart-shipping-tooltip {
  border: solid 1px #000;
  width: calc(100% - 30px);
  position: absolute;
  padding: 25px 44px;
  background: #fff;
  bottom: 134px;
  z-index: 999;

  @include media-breakpoint-up(md){
    bottom: 145px;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  &__title {
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  &__item {
    font-size: 12px;
    color: #787878;
    margin-top: 25px;
    line-height: 1.5;

    & > span:first-child {
      color: #000;
      font-size: 14px;
    }
  }
}

.ui-tooltip {
  padding: 10px;
  color: #000;
  border: solid 1px #000;
  border-radius: 0;
  font-family: "Graphik", sans-serif;
  font-weight: normal;
  font-size: 12px;
  box-shadow: none;

  & > * {
    border: none;
  }
}

.free-shipping-label {
  color: #000;
  background: #ecb7b7;
  display: inline-block;
  padding: 3px 4px 3px 4px;
  line-height: 1;
}