.store-item {

  border: solid 1px #000;

  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  &__metas {
    padding: 25px;
    text-align: center;
    border-top: solid 1px #000;
  }

  &__name {
    text-transform: uppercase;
    font-family: $theme-title-font;
    font-weight: $theme-title-font-weight;
    line-height: 1.1;
    letter-spacing: 0.05rem;
    font-size: 1.75rem;
    margin-bottom: 10px;
    display: inline-block;
    padding-bottom: 3px;
    border-bottom: solid 1px map-get($pt-colors, 'black');
  }

  &__address {

    font-size: 1.3rem;
    color: #333;

    .street-address {
      display: block;
    }
  }
}

a.store-link {
  @include link-colors(
                  map_get($pt-colors, 'black'),
                  map_get($pt-colors, 'black'),
                  map_get($pt-colors, 'black'),
                  map_get($pt-colors, 'black')
  );
}

.store-map-wrapper {
  position: relative;
  margin-bottom: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.store-map {

  &__metas {
    background: map_get($pt-colors, 'white');
    padding: 15px;
    width: 350px;
    position: absolute;
    bottom: 30px;
    left: calc(50% - 175px);
    text-align: center;
    font-size: 1.2rem;
    border:solid 1px #000;
    z-index: 10;

    span.place-name {
      display: block;
      text-transform: uppercase;
      font-family: $theme-title-font;
      font-weight: $theme-title-font-weight;
      line-height: 1.1;
      letter-spacing: 0.05rem;
      font-size: 1.5rem;
      margin-bottom: 4px;
    }
  }
}